import { useState } from 'react';
import { isTestEnv } from '../../utils/commons';
import useIsomorphicLayoutEffect from '../useIsomorphicLayoutEffect';
import useEventListener from '../useEventListener';

const DESKTOP_WITDTH = 1200;

export function getSize(): number {
  if (isTestEnv() || !document) return DESKTOP_WITDTH;

  return document.body.clientWidth;
}

type UseWindowWidthParams = {
  onChange?: (windowWidth: number) => void;
};

export default function useWindowWidth(params?: UseWindowWidthParams): number {
  const [width, setWidth] = useState(0);

  function handleResize(): void {
    const size = getSize();

    setWidth(size);
    if (params && params.onChange) params.onChange(size);
  }

  useEventListener('resize', handleResize);

  useIsomorphicLayoutEffect((): void => {
    handleResize();
  }, []);

  return width;
}
