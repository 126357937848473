import isString from 'lodash/isString';

export const wait = (ms: number): Promise<null> =>
  new Promise((resolve) => {
    setTimeout(() => resolve(null), ms);
  });

export const retryWithDelay = async <R = void>(
  fn: () => any,
  retries = 3,
  interval = 50,
  finalErr = 'Retry failed',
): Promise<R> => {
  try {
    // Try
    const response = await fn();
    if (!response) throw new Error('Not yet');

    return response;
  } catch (err) {
    // If no retries left throw error
    if (retries <= 0) {
      return Promise.reject(finalErr);
    }

    // Delay the next call
    await wait(interval);

    // Recursively call the same func
    return retryWithDelay(fn, retries - 1, interval, finalErr);
  }
};

export function numberWithCommas(x: number | string): string {
  let value: number | string = x;

  if (isString(value)) value = parseFloat((value as string).replace(/,/g, ''));

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export function numberWithThousands(x: number, necessaryLength = 4): string {
  const xToString = x.toString();

  if (xToString.length < 4 || xToString.length < necessaryLength) return numberWithCommas(xToString);

  return `${xToString.slice(0, -3)}k`;
}

export const stringReplaceAll = (str: string, searchValue: string, replaceValue: string): string => {
  return str.split(searchValue).join(replaceValue);
};

export function isProd(): boolean {
  return process.env.NODE_ENV === 'production';
}

export function isClient(): boolean {
  return typeof window !== 'undefined';
}

export function isTestEnv(): boolean {
  return process.env.NODE_ENV === 'test';
}

export function getWindowWidth(): number {
  return isClient() ? window.innerWidth : 0;
}

const MOBILE_SIZE = 576;

export function isMobileScreenSize(): boolean {
  return isClient() && window.matchMedia
    ? window.matchMedia(`only screen and (max-width: ${MOBILE_SIZE}px)`).matches
    : false;
}

export function scrollToHeight(height: number): void {
  window.scroll({
    left: 0,
    top: height,
    behavior: 'smooth',
  });
}

const MOBILE_REGEX =
  // eslint-disable-next-line max-len
  /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|pad|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser.*mobile|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;

export const serverSideIsMobile = (userAgent: string): boolean => {
  return MOBILE_REGEX.test(userAgent);
};

/**
 * This function allows to animate the scroll with an specific duration without
 * depending on the limitations of the different platforms (Safari).
 *
 * @param targetPosition - Positive integer that indicates the position to scroll in the Y axis.
 * @param duration - Duration of the scroll animation.
 */
function smoothScroll(targetPosition: number, duration: number): void {
  function easeInOutCubic(t: number): number {
    return t < 0.5 ? 4 * t * t * t : 1 - (-2 * t + 2) ** 3 / 2;
  }

  const startPosition = window.scrollY;
  const distance = targetPosition - startPosition;
  let startTime: number | null = null;

  function animation(currentTime: number): void {
    if (startTime === null) startTime = currentTime;

    const elapsed = currentTime - startTime;
    const progress = Math.min(elapsed / duration, 1);
    const ease = easeInOutCubic(progress);

    window.scrollTo(0, startPosition + distance * ease);

    if (elapsed < duration) {
      requestAnimationFrame(animation);
    }
  }

  requestAnimationFrame(animation);
}

interface ScrollToElementOptions {
  id: string;
  timeout?: number;
  offset?: number;
  useElementHeight?: boolean;
  duration?: number;
}

/**
 * Scrolls to element.
 *
 * @param id - Element's ID, used to search the element in the document.
 * @param timeout - Delay before the scroll.
 * @param offset - Additional scroll offset in pixels.
 * @param useElementHeight - Boolean that indicates if the scroll should take the element's height in account.
 * @param duration - Duration of the scroll animation.
 */

export function scrollToElement({
  id,
  timeout = 500,
  offset = 0,
  useElementHeight = true,
  duration = undefined,
}: ScrollToElementOptions): void {
  const element: HTMLElement | null = document.getElementById(id);
  const windowWidth = getWindowWidth();

  const isMobile = windowWidth <= 768;

  const topOffset = isMobile ? 150 : 100;

  if (element) {
    const runScroll = (): void => {
      const { top, height } = element.getBoundingClientRect();

      let heightOffset = 0;

      if (useElementHeight) {
        if (height < 100) heightOffset = height * 0.2;
        else if (height >= 300 && height <= 500) heightOffset = height * 0.7;
        else if (height > window.innerHeight) heightOffset = window.innerHeight * 0.8;
        else {
          heightOffset = height * 0.9;
        }
      }

      const elementYPos = top + window.scrollY + heightOffset + offset - topOffset;

      /**
       * If there is a specific duration use the custom implementation. Else, the native one.
       */

      if (duration) {
        smoothScroll(top + window.scrollY + heightOffset + offset - topOffset, duration);
      } else {
        window.scroll({
          left: 0,
          top: elementYPos,
          behavior: 'smooth',
        });
      }
    };

    if (timeout)
      setTimeout(() => {
        runScroll();
      }, timeout);
    else runScroll();
  }
}
