import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import qs from 'qs';
import fetch from '../../utils/fetchService';
import { Deal, DealsResult } from '../../types/deal';

const DEALS_LIST_ENDPOINT = '/promos/list-brands-deals/';

interface UseFetchBrandsDeals {
  deals: Deal[];
  currentDeal: Deal | undefined;
  setCurrentDeal: Dispatch<SetStateAction<Deal | undefined>>;
  handleGetDeal: (deal: Deal) => void;
}

const useFetchBrandsDeals = (
  shouldFetch = true,
  limit = 12,
  offset = 0,
  onlyRebate: boolean = true,
): UseFetchBrandsDeals => {
  const [deals, setDeals] = useState<Deal[]>([]);
  const [currentDeal, setCurrentDeal] = useState<Deal | undefined>(undefined);

  const handleGetDeal = (deal: Deal): void => {
    setCurrentDeal(deal);
  };

  useEffect(() => {
    if (shouldFetch) {
      fetch
        .get<DealsResult>(
          `${DEALS_LIST_ENDPOINT}${qs.stringify(
            {
              limit,
              offset,
              only_rebate: onlyRebate,
            },
            { indices: false, addQueryPrefix: true, skipNulls: true },
          )}`,
        )
        .then((response) => {
          setDeals(response ? response.results : []);
        })
        .catch((err) => {
          console.error('ERROR', err);
        });
    }
  }, [limit, offset]);

  return {
    deals,
    currentDeal,
    handleGetDeal,
    setCurrentDeal,
  };
};

export default useFetchBrandsDeals;
