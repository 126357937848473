import React, { memo, RefObject } from 'react';
import { OverlayTrigger, Tooltip as BSTooltip } from 'react-bootstrap';

export type OverlayTriggerType = 'hover' | 'click' | 'focus';
export type TooltipPlacement =
  | 'auto-start'
  | 'auto'
  | 'auto-end'
  | 'top-start'
  | 'top'
  | 'top-end'
  | 'right-start'
  | 'right'
  | 'right-end'
  | 'bottom-end'
  | 'bottom'
  | 'bottom-start'
  | 'left-end'
  | 'left'
  | 'left-start';

type DOMContainer<T extends HTMLElement = HTMLElement> = T | RefObject<T> | null | (() => T | RefObject<T> | null);

type TooltipProps = {
  id: string;
  placement?: TooltipPlacement;
  trigger?: OverlayTriggerType | OverlayTriggerType[];
  defaultShow?: boolean;
  content?: string | JSX.Element;
  container?: DOMContainer<HTMLElement>;
  color?: 'dark' | 'light' | 'white';
  children: any;
  show?: boolean;
  enabled?: boolean;
};

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  id,
  placement = 'top',
  trigger = ['hover', 'focus'],
  defaultShow = false,
  show = undefined,
  content,
  container,
  children,
  color = 'dark',
  enabled = true,
}: TooltipProps) => {
  const tooltipColor = {
    dark: 'dark-tooltip',
    light: 'light-tooltip',
    white: 'white-tooltip',
  };

  if (!enabled) {
    return children;
  }

  return (
    <OverlayTrigger
      show={show}
      defaultShow={defaultShow}
      key={id}
      placement={placement}
      trigger={trigger}
      container={container}
      overlay={
        <BSTooltip id={id} className={`${tooltipColor[color]}`}>
          {content}
        </BSTooltip>
      }
    >
      {children}
    </OverlayTrigger>
  );
};

export default memo(Tooltip);
