import React, { memo, ReactNode, useEffect } from 'react';
import cls from 'classnames';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import type { EmblaCarouselType, EmblaOptionsType } from 'embla-carousel';
import SmoothChevronIcon from '../../atoms/Icons/SmoothChevron';
import { useDotButton } from '../../../hooks/useDotButton';
import { usePrevNextButtons } from '../../../hooks/usePrevNextButtons';

export type CarouselChildWithCaption = {
  item: JSX.Element;
  caption: JSX.Element;
};

export interface CarouselChildProps {
  index?: number;
  startIndex?: number;
  emblaApi?: EmblaCarouselType;
}

export type CarouselPropsType = {
  id: string;
  children: React.ReactElement[] | CarouselChildWithCaption[];
  className?: string;
  childClassname?: string;
  startIndex?: number;
  emblaOptions?: EmblaOptionsType;
  autoplayOptions?: AutoplayOptionsType;
  autoplay?: boolean;
  showArrows?: boolean;
  showDots?: boolean;
  onSlideChange?: (index: number) => void;
  onCarouselMount?: (emblaApi: EmblaCarouselType) => void;
};

export const CAROUSEL_CONTAINER = 'carousel-container';
export const CAROUSEL_CONTROL_PREV_BTN = 'carousel-control-prev-btn';
export const CAROUSEL_CONTROL_NEXT_BTN = 'carousel-control-next-btn';
export const CAROUSEL_BTN_PREV = 'chevron-carousel-prev';
export const CAROUSEL_BTN_NEXT = 'chevron-carousel-next';
export const CAROUSEL_DOTS = 'carousel-dots';

export const EMBLA_OPTIONS: EmblaOptionsType = {
  active: true,
  align: 'center',
  axis: 'x',
  breakpoints: {},
  container: null,
  containScroll: 'trimSnaps',
  direction: 'ltr',
  dragFree: false,
  dragThreshold: 10,
  duration: 25,
  inViewThreshold: 0,
  loop: true,
  skipSnaps: false,
  slides: null,
  slidesToScroll: 1,
  startIndex: 0,
  watchDrag: true,
  watchFocus: true,
  watchResize: true,
  watchSlides: true,
};

export const AUTOPLAY_OPTIONS: AutoplayOptionsType = {
  delay: 4000,
  jump: false,
  playOnInit: true,
  stopOnInteraction: false,
  stopOnMouseEnter: false,
  stopOnFocusIn: false,
  stopOnLastSnap: false,
  rootNode: null,
};

const CHEVRON_COLOR = '#4561A4';

const Carousel: React.FC<CarouselPropsType> = ({
  id,
  children,
  className = '',
  childClassname = '',
  startIndex = 0,
  emblaOptions = EMBLA_OPTIONS,
  autoplayOptions = AUTOPLAY_OPTIONS,
  autoplay = true,
  showArrows = true,
  showDots = true,
  onSlideChange,
  onCarouselMount,
}: CarouselPropsType) => {
  if (!children?.length) return null;

  // Only add AutoPlay plugin if autoplay is true
  const plugins = autoplay ? [Autoplay(autoplayOptions)] : [];

  const [carouselRef, emblaApi] = useEmblaCarousel({ ...emblaOptions, startIndex }, plugins);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);
  const { selectedIndex, onDotButtonClick } = useDotButton(emblaApi);

  const showControls = showDots || showArrows;
  const hasMoreThanOneItem = children && children.length > 1;

  const renderChild = (child: React.ReactNode, idx: number): ReactNode => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { index: idx, startIndex, emblaApi } as CarouselChildProps);
    }
    return child;
  };

  useEffect(() => {
    if (emblaApi && onCarouselMount) onCarouselMount(emblaApi);
  }, [emblaApi, onCarouselMount]);

  useEffect(() => {
    if (emblaApi && onSlideChange)
      emblaApi.on('select', () => {
        if (!emblaApi) return;

        const currentIndex = emblaApi.selectedScrollSnap();

        onSlideChange(currentIndex);
      });
  }, [emblaApi, onSlideChange]);

  return (
    <div
      id={id}
      data-testid={id}
      className={cls('carousel embla', className, { 'not-many-items': !hasMoreThanOneItem })}
      ref={carouselRef}
    >
      <div data-testid={CAROUSEL_CONTAINER} className="embla__container">
        {children?.map((child, idx) => (
          <div
            // eslint-disable-next-line
            key={`carousel-slide-${idx}`}
            className={`embla__slide ${childClassname} ${idx === selectedIndex ? 'active' : ''}`}
          >
            {renderChild('item' in child ? child.item : child, idx)}
          </div>
        ))}
      </div>
      {hasMoreThanOneItem && showControls && (
        <div className="embla__controls">
          {showArrows && (
            <>
              <button
                type="button"
                data-testid={CAROUSEL_CONTROL_PREV_BTN}
                aria-label="Previous"
                className="carousel-control-prev-btn"
                disabled={prevBtnDisabled}
                onClick={onPrevButtonClick}
              >
                <SmoothChevronIcon className={CAROUSEL_BTN_PREV} color={CHEVRON_COLOR} />
              </button>
              <button
                type="button"
                data-testid={CAROUSEL_CONTROL_NEXT_BTN}
                key="carousel-control-next-btn"
                aria-label="Next"
                className="carousel-control-next-btn"
                disabled={nextBtnDisabled}
                onClick={onNextButtonClick}
              >
                <SmoothChevronIcon className={CAROUSEL_BTN_NEXT} color={CHEVRON_COLOR} rotate={180} />
              </button>
            </>
          )}

          {showDots && (
            <div data-testid={CAROUSEL_DOTS} className="carousel-dots">
              {children.map((item, index) => (
                <button
                  type="button"
                  // eslint-disable-next-line
                  key={`carousel-dot-${index}`}
                  aria-label={`Go to slide ${index + 1}`}
                  className={cls('carousel-dot', { active: index === selectedIndex })}
                  onClick={(): void => onDotButtonClick(index)}
                />
              ))}
            </div>
          )}
        </div>
      )}

      {children.length > 1 && 'caption' in children[selectedIndex] && (
        <div className="carousel-caption">{(children[selectedIndex] as CarouselChildWithCaption).caption}</div>
      )}
    </div>
  );
};

export default memo(Carousel);
