import { getCookie } from 'typescript-cookie';

export type FetchParams = Record<string, string | number | boolean | null>;
export type FetchBody = Record<string, unknown> | Record<string, unknown>[];
export type ApiVersion = 1 | 2;

export const API_VERSION_2_PREFIX = 'api/v2';
export const DEFAULT_API_VERSION: ApiVersion = 2;
export const defaultHost = process.env.NEXT_PUBLIC_GOODBED_HOST || '';

const STAGE_SERVER = 'stage';
const DEV_SERVER = 'dev';

export class FetchService {
  baseURL: string;

  constructor(baseURL = defaultHost) {
    this.baseURL = baseURL.replace(/\/$/, '');
  }

  async get<T>(
    endpoint: string,
    params: FetchParams = {},
    options: RequestInit = {},
    apiVersion: ApiVersion = DEFAULT_API_VERSION,
  ): Promise<T | null> {
    const url = new URL(this.apiUrl(endpoint, apiVersion));
    Object.keys(params).forEach((key) => url.searchParams.append(key, String(params[key])));

    const response = await fetch(url.toString(), { method: 'GET', ...this.buildOptions(options) });

    if (response.ok) {
      const text = await response.text();
      return text ? JSON.parse(text) : null;
    }
    throw new Error(response.statusText);
  }

  async post<T>(
    endpoint: string,
    body: FetchBody = {},
    options: RequestInit = {},
    apiVersion: ApiVersion = DEFAULT_API_VERSION,
  ): Promise<T | null> {
    const response = await fetch(this.apiUrl(endpoint, apiVersion), {
      ...this.buildOptions(options),
      method: 'POST',
      body: JSON.stringify(body),
    });

    if (response.ok) {
      const text = await response.text();
      return text ? JSON.parse(text) : null;
    }
    throw new Error(response.statusText);
  }

  async put<T>(
    endpoint: string,
    body: FetchBody = {},
    options: RequestInit = {},
    apiVersion: ApiVersion = DEFAULT_API_VERSION,
  ): Promise<T | null> {
    const response = await fetch(this.apiUrl(endpoint, apiVersion), {
      ...this.buildOptions(options),
      method: 'PUT',
      body: JSON.stringify(body),
    });
    if (response.ok) {
      const text = await response.text();
      return text ? JSON.parse(text) : null;
    }
    throw new Error(response.statusText);
  }

  async delete<T>(endpoint: string, options: RequestInit = {}, apiVersion: ApiVersion = 2): Promise<T | null> {
    const response = await fetch(this.apiUrl(endpoint, apiVersion), {
      ...this.buildOptions(options),
      method: 'DELETE',
    });
    if (response.ok) {
      const text = await response.text();
      return text ? JSON.parse(text) : null;
    }
    throw new Error(response.statusText);
  }

  private apiUrl(endpoint: string, apiVersion: ApiVersion = DEFAULT_API_VERSION): string {
    if (endpoint.startsWith('http')) {
      return endpoint;
    }

    let tartgetEndpoint = endpoint;
    if (endpoint.startsWith('/')) {
      tartgetEndpoint = endpoint.replace(/^\//g, '');
    }

    // add trailing slash if not present to avoid enforced redirects from the server
    if (!endpoint.endsWith('/') && endpoint.indexOf('?') === -1) {
      tartgetEndpoint = `${endpoint}/`;
    }

    const apiVersionPrefix = apiVersion === 2 ? `/${API_VERSION_2_PREFIX}/` : '/';

    const relativeUrl = `${apiVersionPrefix}${tartgetEndpoint}`.replace('//', '/');

    const baseUrl = this.baseURL || window?.location?.origin;
    return `${baseUrl}${relativeUrl}`;
  }

  private buildOptions(options: RequestInit = {}): RequestInit {
    const passedHeaders = options.headers || {};
    const csrfToken = (getCookie && getCookie('csrftoken')) || '';
    const result: RequestInit = {
      credentials: 'include',
      headers: { 'Content-Type': 'application/json', 'X-CSRFToken': csrfToken, ...passedHeaders },
      ...options,
    };

    if (this.baseURL && (this.baseURL.indexOf(STAGE_SERVER) !== -1 || this.baseURL.indexOf(DEV_SERVER) !== -1)) {
      const base64 = btoa(`${process.env.NEXT_PUBLIC_GOODBED_USERNAME!}:${process.env.NEXT_PUBLIC_GOODBED_PASSWORD!}`);
      result.headers = { ...result.headers, Authorization: `Basic ${base64}` };
    }

    return result;
  }
}

export default new FetchService();
