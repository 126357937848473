import { Retailer } from './retailer';

export enum PartnerType {
  AFFILIATE_PARTNER = 'Affiliate Partner',
  LOCAL_PARTNER = 'Local Partner',
}

export enum PromotionType {
  BRAND = 'Brand',
  ONLINE = 'Online',
  RETAILER = 'Retailer',
  REBATE = 'Rebate',
  LOCAL_REBATE = 'Local Rebate',
  AFFILIATE_REBATE = 'Affiliate Rebate',
}

export enum PayoutType {
  FIXED = 'FIXED',
  PERCENTAGE = 'PERCENTAGE',
}

export type NewPromotion = {
  partner_type: PartnerType;
  type: PromotionType;
  promo_code_is_required: boolean;
  should_hide_incognito_instructions: boolean;
} & Promotion;

export type Promotion = {
  classname?: 'brandpromotion' | 'retailerpromotion' | 'promotion' | 'rebatepromotion';
  claimable: boolean;
  code: string;
  custom_landing_page: boolean;
  description: string;
  ends: Date | string;
  external_url: string;
  external_domain: string;
  id: number;
  is_current: boolean;
  link_only: boolean;
  outbound_url: string;
  promotion_type: PromotionType;
  starts: Date | string;
  subtitle: string;
  redeem: number;
  retailer: Retailer;
  reward_description: string;
  reward_description_short: string;
  fixed_payout_amount: number;
  percentage_payout: number;
  payout_type?: PayoutType;
  reward_image?: string;
  title: string;
  title_short: string;
};

export type BrandPromotion = {
  active: boolean;
  build?: undefined;
  code?: string;
  collection?: undefined;
  coupon?: undefined;
  custom_landing_page: boolean;
  description?: string;
  ends?: string;
  featured: number;
  id: number;
  line?: number;
  link_only: boolean;
  manufacturer: number;
  mattress: number;
  mattress_class?: string;
  note?: string;
  priority: number;
  product_logo?: string;
  redeem: number;
  starts: string;
  subtitle?: string;
  title?: string;
  title_short: string;
  url?: string;
};

// This will include all current promos (retailer promotions, brand promotions and claimable promotions).
export type AnyPromotion = {
  classname: 'brandpromotion' | 'retailerpromotion' | 'promotion' | 'rebatepromotion';
  description?: string;
  id: number;
  logo?: string;
  product_name?: string;
  external_url?: string;
  external_domain?: string;
  outbound_url?: string;
  retailer_name: string;
  store_name: string;
  title: string;
  title_short: string;
  promotion_type: PromotionType;
};
