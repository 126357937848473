import React, { lazy, Suspense, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { EDITORIAL_REVIEW_MODAL_ID } from 'shared-ui/src/components/molecules/EditorialReviewModal';
import PropTypes from 'prop-types';
import { handleComponentUnmount } from './utils';

const EDITORIAL_REVIEW_MODAL_BUTTONS_CLASS = 'editorial-review-modal-btn';

const LazySUEditorialReviewModal = lazy(
  () =>
    import(/* webpackChunkName: "editorial-review-modal" */ 'shared-ui/src/components/molecules/EditorialReviewModal'),
);

function EditorialReviewModal({ show = false, slug, classname, unmount, initialIndex, shouldCacheData, isHomePage }) {
  const [showModal, setShowModal] = useState(show);

  return (
    <Suspense fallback={null}>
      <LazySUEditorialReviewModal
        show={showModal}
        slug={slug}
        classname={classname}
        initialIndex={initialIndex}
        shouldCacheData={shouldCacheData}
        onHide={() => {
          setShowModal(false);
        }}
        unmount={unmount}
        isHomePage={isHomePage}
      />
    </Suspense>
  );
}

EditorialReviewModal.propTypes = {
  show: PropTypes.bool.isRequired,
  slug: PropTypes.string.isRequired,
  classname: PropTypes.string.isRequired,
  unmount: PropTypes.func,
  isHomePage: PropTypes.bool,
};

export function renderEditorialReviewModal(id = EDITORIAL_REVIEW_MODAL_ID) {
  const element = document.getElementById(id);

  const buttons = document.querySelectorAll(`[class=${EDITORIAL_REVIEW_MODAL_BUTTONS_CLASS}]`);

  buttons.forEach((btn) => {
    const slug = btn.getAttribute('data-slug');
    const classname = btn.getAttribute('data-classname');
    const initialIndex = btn.getAttribute('data-index');
    const shouldCacheData = btn.getAttribute('data-should-cache');
    const isHomePage = btn.getAttribute('data-is-home') === 'true';

    btn.addEventListener('click', () => {
      const root = createRoot(element);
      root.render(
        <EditorialReviewModal
          show={!!slug && !!classname}
          slug={slug}
          classname={classname}
          initialIndex={initialIndex ? parseInt(initialIndex, 10) : undefined}
          shouldCacheData={shouldCacheData === 'true'}
          isHomePage={isHomePage}
          unmount={() => {
            handleComponentUnmount(id, root);
          }}
        />,
      );
    });
  });
}

export default EditorialReviewModal;
