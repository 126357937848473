import {
    CTA_CLICK_EVENT,
    ONLINE_PROMO_INTERACTION_EVENT,
    PAGE_DATA_LOADED,
    PRODUCT_QUICK_VIEW_EVENT,
    QUALIFYING_ACTION_EVENT,
    QUIZ_INTERACTION_EVENT,
    RETAILER_CASHBACK_CODE_INTERACTION_EVENT,
    SITE_SEARCH_EVENT,
    STORE_LOCATOR_CLICK_EVENT,
} from "./event-objects";

import {
    AccountAuthenticationEvent,
    CashbackCodeEvent,
    CTAClick,
    OnlinePromoInteractionEvent,
    PageDataLoadedEvent,
    PageLoadUserData,
    ProductQuickViewEvent,
    QualifyingActionEvent,
    QuizInteractionEvent,
    SiteSearchEvent,
    StoreLocatorResult,
} from "./types";

declare global {
    interface Window {
        dataLayer: object[];
        posthog: any;
    }
}

// This is for unit testing
type FakeWindow = {
    dataLayer: undefined;
    posthog?: any;
};

export default class DataLayerService {
    window: undefined | Window | FakeWindow;

    constructor(window: undefined | Window | FakeWindow) {
        this.window = window;
    }

    pushToDataLayer(eventObject: any): void {
        const { window } = this;

        if (window) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push(eventObject);

            if (window.posthog && eventObject.event) {
                window.posthog.capture(eventObject.event, eventObject);
            }
        }
    }

    pageDataLoadedEvent(
        event: PageDataLoadedEvent,
        user: PageLoadUserData,
    ): void {
        const pageLoadedEvent = {
            event: "dle_pageDataLoaded",
            page: { ...PAGE_DATA_LOADED.page, ...event },
            user: { ...QUALIFYING_ACTION_EVENT, ...user },
        };
        this.pushToDataLayer(pageLoadedEvent);

        // TODO: remove this once we don't need to support old GA
        this.pushToDataLayer({
            event: "content-view",
            "content-name": window.location.pathname,
        });
    }

    accountAuthenticationEvent(event: AccountAuthenticationEvent): void {
        const accountAuthenticationEvent = {
            event: "dle_accountAuth",
            account: { ...QUALIFYING_ACTION_EVENT, ...event },
        };
        this.pushToDataLayer(accountAuthenticationEvent);
    }

    CTAClickEvent(event: CTAClick): void {
        const ctaClickEventObject = {
            event: "dle_ctaClick",
            cta: { ...CTA_CLICK_EVENT, ...event },
        };
        this.pushToDataLayer(ctaClickEventObject);
    }

    quizInteractionEvent(event: QuizInteractionEvent): void {
        const quizInteractionEventObject = {
            event: "dle_mattressQuiz",
            quiz: { ...QUIZ_INTERACTION_EVENT, ...event },
        };
        this.pushToDataLayer(quizInteractionEventObject);
    }

    onlinePromotionInteractionEvent(event: OnlinePromoInteractionEvent): void {
        const onlinePromoInterationEventObject = {
            event: "dle_onlinePromo",
            promo: { ...ONLINE_PROMO_INTERACTION_EVENT, ...event },
        };
        this.pushToDataLayer(onlinePromoInterationEventObject);
    }

    productQuickViewEvent(event: ProductQuickViewEvent): void {
        const productQuickViewEventObject = {
            event: "dle_productQuickView",
            quickView: { ...PRODUCT_QUICK_VIEW_EVENT, ...event },
        };
        this.pushToDataLayer(productQuickViewEventObject);
    }

    qualifyingActionEvent(event: QualifyingActionEvent): void {
        const qualifyingActionEventObject = {
            event: "dle_qualAction",
            account: { ...QUALIFYING_ACTION_EVENT, ...event },
        };
        this.pushToDataLayer(qualifyingActionEventObject);
    }

    retailerCashbackCodeInterationEvent(event: CashbackCodeEvent): void {
        const retailerCashbackCodeInterationEventObject = {
            event: "dle_rebateOffer",
            rebate: { ...RETAILER_CASHBACK_CODE_INTERACTION_EVENT, ...event },
        };

        this.pushToDataLayer(retailerCashbackCodeInterationEventObject);
    }

    siteSearchEvent(event: SiteSearchEvent): void {
        const siteSearchEventObject = {
            event: "dle_siteSearch",
            search: { ...SITE_SEARCH_EVENT, ...event },
        };
        this.pushToDataLayer(siteSearchEventObject);
    }

    storeLocatorClickThroughEvent(event: StoreLocatorResult): void {
        const storeLocatorClickObject = {
            event: "dle_storeLocatorClick",
            store: { ...STORE_LOCATOR_CLICK_EVENT, ...event },
        };
        this.pushToDataLayer(storeLocatorClickObject);
    }
}
