/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';
import { ICON_CLASS } from '../../../constants/commons';

const SmoothStarIcon: FC<IconProps> = ({ className = '', color = 'white' }) => (
  <svg
    className={`${ICON_CLASS} ${className} star-icon`}
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="M19.3536 7.13798L13.358 6.25494L10.6778 0.748556C10.6046 0.597795 10.4841 0.47575 10.3354 0.401565C9.96227 0.214908 9.50887 0.370456 9.32232 0.748556L6.64213 6.25494L0.646516 7.13798C0.481218 7.16191 0.330088 7.24088 0.214379 7.36053C0.0744931 7.50623 -0.00259025 7.70226 6.6464e-05 7.90552C0.00272317 8.10879 0.0849026 8.30268 0.228547 8.44458L4.56645 12.7305L3.5416 18.7825C3.51757 18.9233 3.53294 19.0681 3.58598 19.2005C3.63901 19.3328 3.72759 19.4475 3.84166 19.5315C3.95573 19.6154 4.09074 19.6653 4.23137 19.6755C4.37199 19.6856 4.51262 19.6557 4.63729 19.589L10 16.7317L15.3628 19.589C15.5092 19.6679 15.6792 19.6943 15.8422 19.6655C16.2531 19.5938 16.5293 19.1989 16.4585 18.7825L15.4336 12.7305L19.7715 8.44458C19.8896 8.32732 19.9675 8.17416 19.9912 8.00665C20.0549 7.58787 19.7668 7.20019 19.3536 7.13798Z"
    />
  </svg>
);

export default SmoothStarIcon;
