import React, { FC, useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useTransition } from 'react-transition-state';
import cls from 'classnames';
import VideoPlayerResumeIcon from '../../atoms/Icons/VideoPlayerResume';

const IFRAME_WMODE = 'wmode=transparent';
const NOT_INCLUDE_SUGGESTED_VIDEOS = 'rel=0';
const VIDEO_PLAYER_OVERLAY_CLASS = 'video-player__overlay';

export const VIDEO_PLAYER_ID = 'gb-video-player';

export interface VideoPlayerProps {
  youtubeEmbedUrl?: string;
  youtubeId?: string;
  title?: string;
  description?: string;
  showCaption?: boolean;
  showCaptionLine?: boolean;
  playOnClick?: boolean;
  playing?: boolean;
  onPlay?: () => void;
  onPause?: () => void;
  onClick?: () => void;
}

const VideoPlayer: FC<VideoPlayerProps> = ({
  youtubeEmbedUrl,
  youtubeId,
  title,
  description,
  showCaption = false,
  showCaptionLine = false,
  playOnClick = true,
  playing = false,
  onPlay,
  onPause,
  onClick,
}) => {
  const [{ status, isMounted }, toggle] = useTransition({
    timeout: 400,
    unmountOnExit: true,
    initialEntered: true,
  });

  const embedCode =
    youtubeId?.indexOf(NOT_INCLUDE_SUGGESTED_VIDEOS) === -1
      ? `${youtubeId}?${NOT_INCLUDE_SUGGESTED_VIDEOS}&${IFRAME_WMODE}`
      : `${youtubeId}?${IFRAME_WMODE}`;

  const [isPlaying, setIsPlaying] = useState(playing);
  const playerOverlayRef = useRef(null);

  useEffect(() => {
    if (playing) toggle(false);
  }, [playing]);

  return (
    <div className="video-player" data-testid={VIDEO_PLAYER_ID}>
      <ReactPlayer
        width="100%"
        height="100%"
        playing={isPlaying}
        url={youtubeEmbedUrl || `https://www.youtube.com/embed/${embedCode}`}
        controls
        onPlay={onPlay}
        onPause={onPause}
        config={{
          youtube: {
            playerVars: {
              rel: 0,
            },
          },
        }}
      />
      {isMounted ? (
        <button
          ref={playerOverlayRef}
          type="button"
          aria-label="play video"
          className={cls(VIDEO_PLAYER_OVERLAY_CLASS, status)}
          onClick={(): void => {
            if (playOnClick) {
              setIsPlaying(true);
              toggle(false);
            } else if (!playOnClick && onClick) onClick();
          }}
        >
          <div className="video-player__play-button">
            <VideoPlayerResumeIcon />
          </div>

          {showCaption && (title || description) ? (
            <div className={cls('video-player__caption', status)}>
              {showCaptionLine && <div className="line" />}
              {title || null}
              {description ? <p>{description}</p> : null}
            </div>
          ) : null}
        </button>
      ) : null}
    </div>
  );
};

export default VideoPlayer;
