export const SHARE_RESULTS_KEY = 'goodbed.coupon.share_results';

export const NAME = 'name';
export const EMAIL = 'email';
export const PASSWORD = 'password';
export const PHONE = 'phone';
export const SEND_EMAIL = 'send_email';
export const SEND_TEXT = 'send_text';

// signin methods consts
export const INVOKE = 'invoke';
export const LOGIN = 'login';
export const REGISTER = 'register';
export const VERIFY = 'verify';
export const EMAIL_INFO = 'email_info';
export const SMS_INFO = 'sms_info';

export const SIGNIN_METHOD_URL = {
  [INVOKE]: INVOKE,
  [LOGIN]: LOGIN,
  [REGISTER]: 'registration',
  [VERIFY]: 'send-verification',
};

export const AUTHENTICATION_FIELDS = {
  [EMAIL]: {
    name: EMAIL,
    label: 'Email this info to me',
    placeholder: 'Email address',
  },
  [NAME]: {
    name: NAME,
    label: 'Name',
    placeholder: 'First name',
  },
  [PASSWORD]: {
    name: PASSWORD,
    label: 'Password',
    placeholder: 'Password',
  },
};
