import React, { FC } from 'react';

export const PROMO_CHIP_TEST_ID = 'promo-chip';

export type PromoChipProps = {
  label: string;
};

const PromoChip: FC<PromoChipProps> = ({ label }) => {
  return (
    <div data-testid={PROMO_CHIP_TEST_ID} className="promo-chip">
      {label}
    </div>
  );
};

export default PromoChip;
