import React from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import useFetchBrandsDeals from 'shared-ui/src/hooks/useFetchBrandsDeals';
import useWindowWidth from 'shared-ui/src/hooks/useWindowWidth';
import SUBrandPromoBox from 'shared-ui/src/components/molecules/BrandPromoBox';

const HOME_SAVE_EXTRA_MONEY_ID = 'save-extra-money';
const DESKTOP_LIMIT = 20;
const MOBILE_LIMIT = 9;

function HomeSaveExtraMoney({ pageRegion, onGetDeal }) {
  const screenWidth = useWindowWidth();
  const { deals } = useFetchBrandsDeals(true, screenWidth > 576 ? DESKTOP_LIMIT : MOBILE_LIMIT);

  return deals.length > 0 ? (
    <div className="best-available-discounts-container">
      <div className="best-available-discounts">
        {deals.map((d) => (
          <SUBrandPromoBox key={d.brand.slug} deal={d} pageRegion={pageRegion} onGetDeal={onGetDeal} />
        ))}
      </div>
    </div>
  ) : null;
}

HomeSaveExtraMoney.propTypes = {
  pageRegion: PropTypes.string.isRequired,
};

export default HomeSaveExtraMoney;

export function renderSaveExtraMoney(onGetDeal, selector = `[id=${HOME_SAVE_EXTRA_MONEY_ID}]`) {
  const elements = document.querySelectorAll(selector);

  elements.forEach((element) => {
    const pageRegion = element.getAttribute('data-page-region');

    const root = createRoot(element);

    root.render(<HomeSaveExtraMoney pageRegion={pageRegion} onGetDeal={onGetDeal} />);
  });
}
