import { isClient } from './commons';

export async function cacheRequestInSessionStorage<T>(fn: () => Promise<T>, key: string): Promise<T> {
  const cachedData = sessionStorage.getItem(key);

  if (cachedData) return JSON.parse(cachedData);

  const result = await fn();
  sessionStorage.setItem(key, JSON.stringify(result));

  return result;
}

export const getCachedDataFromSessionStorage = <T>(key: string): T | null => {
  const cachedDataString = (isClient() && sessionStorage.getItem(key)) || null;
  if (!cachedDataString) return null;

  const cachedData = cachedDataString ? JSON.parse(cachedDataString) : null;

  return cachedData;
};

export const eliminateCacheDataFromSessionStorage = (key: string): void => {
  if (isClient()) sessionStorage.removeItem(key);
};

export const setCacheDataToSessionStorage = <T>(key: string, value: T): void => {
  if (isClient()) sessionStorage.setItem(key, JSON.stringify(value));
};
