import React from 'react';
import styles from './DiscontinuedCaption.module.scss';

type DiscontinuedCaptionProps = {
  show: boolean;
};

const DiscontinuedCaption: React.FC<DiscontinuedCaptionProps> = ({ show }) => {
  return show ? <figcaption className={styles.discontinuedCaption}>No Longer Available</figcaption> : null;
};

export default DiscontinuedCaption;
