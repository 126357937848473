import { LINE, MANUFACTURER, MATTRESS, COLLECTION } from '../constants/commons';
import {
  FILTER_ADJUSTABLE_BASE_COMPATIBILITY_KEY,
  FILTER_BACK_SUPPORT_KEY,
  FILTER_BACK_SUPPORT_URL_PARAMS_PREFIX,
  FILTER_BOUNCE_KEY,
  FILTER_COMFORT_TYPE_KEY,
  FILTER_COOLING_KEY,
  FILTER_CUSHIONING_DEPTH_KEY,
  FILTER_EDGE_SUPPORT_KEY,
  FILTER_GREEN_FEATURES_KEY,
  FILTER_INDENTATION_COVERAGE_KEY,
  FILTER_MANUFACTURER_KEY,
  FILTER_MEMORY_FEEL_KEY,
  FILTER_MOTION_ISOLATION_KEY,
  FILTER_PRESSURE_RELIEF_KEY,
  FILTER_PRESSURE_RELIEF_URL_PARAMS_PREFIX,
  FILTER_RATING_KEY,
  FILTER_SLEEPER_ONE_KEY,
  FILTER_SLEEPER_TWO_KEY,
  FILTER_SLEEP_POSITION_KEY,
  FILTER_SLEEP_POSITION_URL_PARAMS_KEY,
  FILTER_SOFTNESS_KEY,
  FILTER_STORE_BRAND_KEY,
  FILTER_STORE_RETAILER_KEY,
  FILTER_SUPPORT_TYPE_KEY,
  FILTER_SURFACE_TYPE_KEY,
  FILTER_WARRANTY_LENGTH_KEY,
  FILTER_WEIGHT_KEY,
  FILTER_WEIGHT_URL_PARAMS_KEY,
  SORT_KEY,
} from '../constants/filter';
import { PopularBrand } from './brands';

export type PartialNull<T> = {
  [P in keyof T]: T[P] | null;
};

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

// what /<classname>/autocomplete/ returns
export interface ObjectLookup {
  classname: string;
  slug: string;
  id: string;
  name: string;
  manufacturer: string;
  line: string;
  collection: string;
  model_name: string;
  unique_id: string;
}

export type MattressStatus = 'Active' | 'Discontinued' | 'None';

export interface ObjectValue {
  name: string;
  value: number;
  description?: string;
}

export type Age = ObjectValue;

export type ComfortPreference = ObjectValue;

export type Sex = ObjectValue;

export type SleepPosition = ObjectValue;

export type Weight = ObjectValue;

export type MatchReasons = {
  description: string;
  importance: string | null;
};

export type Reasons = {
  considerations: MatchReasons[];
  match_reasons: MatchReasons[];
};

export type Size = {
  name: string;
  value: string | number;
};

export type Price = {
  price: string;
  promo?: {
    id: number;
    outbound_url: string;
    subtitle: string;
    title: string;
  };
  size: string;
  size_code: number;
};

export type Video = {
  description: string;
  title: string;
  video_file: string;
  youtube_url?: string;
  youtube_embed_url?: string;
};

export type Image = {
  description?: string;
  image: string;
  title: string;
};

export type Picture = {
  description: string;
  image: string;
  object_id: number;
  thumbnail: string;
  title: string;
};

export type Media = {
  pictures: Picture[];
  videos: Video[];
};
export type Multimidea = (Video | Picture)[];

export enum MediaTypes {
  IMAGE = 'Image',
  VIDEO = 'Video',
}

export type Plushness = {
  value: number;
  name: string;
  css: string;
};

export type Softness = {
  plushness: Plushness;
  reasons: Reasons;
  user_plushness: Plushness;
  partners_plushness: Plushness;
};

export type BreadcrumbsItem = {
  get_absolute_url: string;
  is_top_object: boolean;
  name: string;
  slug: string;
  classname?: string;
};

export type Breadcrumbs = {
  [MANUFACTURER]: BreadcrumbsItem;
  [MATTRESS]?: BreadcrumbsItem;
  [LINE]?: BreadcrumbsItem;
  [COLLECTION]?: BreadcrumbsItem;
};

export type Condition = {
  id: number;
  full_name: string;
  short_description: string;
  short_description_praise?: string;
  short_description_complaint?: string;
};

export type Tag = {
  id: number;
  name: string;
  full_name: string;
  short_description: string;
  css: string;
};

export type ConditionTags = {
  Condition: Condition[];
  ['Condition (Kids)']: Condition[];
  ['Product Condition Issues']: Condition[];
};

export type MattressChoices = {
  temperature: ObjectValue[];
  date_purchased_choice_general: ObjectValue[];
  date_purchased_choice: ObjectValue[];
  date_purchased_years: ObjectValue[];
  durability: ObjectValue[];
  pressure_relief: ObjectValue[];
  sleep: ObjectValue[];
  replacement_reason: ObjectValue[];
  product_mattress_size: ObjectValue[];
  previous_mattress_type: ObjectValue[];
  mattress_type: ObjectValue[];
  mattress_size: ObjectValue[];
  target_person: ObjectValue[];
  redeem: ObjectValue[];
  comfort_preference: ObjectValue[];
  sex: ObjectValue[];
  age: ObjectValue[];
  weight: ObjectValue[];
  motion_isolation: ObjectValue[];
  edge_support: ObjectValue[];
  adjustable_comfort: ObjectValue[];
  natural_materials: ObjectValue[];
  dual_comfort: ObjectValue[];
  cushioning: ObjectValue[];
  temperature_feel: ObjectValue[];
  responsiveness: ObjectValue[];
  has_springs: ObjectValue[];
  has_store: string;
  is_pillow_top: ObjectValue[];
  sleep_position: ObjectValue[];
  popular_brands: PopularBrand[];
  condition_tags: ConditionTags;
  querystring_map: any[];
  hierarchy: string[];
  condition_child_tags: number[];
  selected_product?: PopularBrand;
};

export interface GoodBedReview {
  subtitle: null | string;
  title: string;
  youtube_id: string;
  youtube_url: string;
  url: string;
}

export type ChoicesDefault = {
  name: string;
  value: string | number;
};

export type MattressTypeChoices = {
  id: number;
  full_name: string;
  css: string;
};

export type BrandFilter = {
  name: string;
  value: string;
  children?: BrandFilter[];
};

export type Filter = {
  key: string;
  title: string;
  data?: ObjectValue[];
  order: string;
};

export type DefaultFilterChoices = {
  [key: string]: ChoicesDefault[];
};

export type Sleeper = typeof FILTER_SLEEPER_ONE_KEY | typeof FILTER_SLEEPER_TWO_KEY;
export type SleeperFilter = typeof FILTER_BACK_SUPPORT_KEY | typeof FILTER_PRESSURE_RELIEF_KEY;
export type SleeperFilterType = typeof FILTER_SLEEP_POSITION_KEY | typeof FILTER_WEIGHT_KEY;
export type SleeperFilterUrlParamsPrefixType =
  | typeof FILTER_BACK_SUPPORT_URL_PARAMS_PREFIX
  | typeof FILTER_PRESSURE_RELIEF_URL_PARAMS_PREFIX;
export type SleeperFilterTypeUrlParamsType =
  | typeof FILTER_SLEEP_POSITION_URL_PARAMS_KEY
  | typeof FILTER_WEIGHT_URL_PARAMS_KEY;

type FilterSleeperChoice = {
  [FILTER_SLEEP_POSITION_KEY]: ChoicesDefault[];
  [FILTER_WEIGHT_KEY]: ChoicesDefault[];
};

export type FilterSleeperTypeValue = {
  [FILTER_SLEEP_POSITION_KEY]?: string[];
  [FILTER_WEIGHT_KEY]?: string[];
};

export type FilterSleeperChoices = {
  [FILTER_SLEEPER_ONE_KEY]: FilterSleeperChoice;
  [FILTER_SLEEPER_TWO_KEY]: FilterSleeperChoice;
};

export type FilterSleeperValue = {
  [FILTER_SLEEPER_ONE_KEY]?: FilterSleeperTypeValue;
  [FILTER_SLEEPER_TWO_KEY]?: FilterSleeperTypeValue;
};

export type FilterChoices = {
  p?: BrandFilter[];
  brand_rating?: ChoicesDefault[];
  [FILTER_SURFACE_TYPE_KEY]?: ChoicesDefault[];
  [FILTER_COMFORT_TYPE_KEY]?: ChoicesDefault[];
  [FILTER_SUPPORT_TYPE_KEY]?: ChoicesDefault[];
  [FILTER_SOFTNESS_KEY]?: ChoicesDefault[];
  [FILTER_CUSHIONING_DEPTH_KEY]?: ChoicesDefault[];
  [FILTER_MEMORY_FEEL_KEY]?: ChoicesDefault[];
  [FILTER_BOUNCE_KEY]?: ChoicesDefault[];
  [FILTER_MOTION_ISOLATION_KEY]?: ChoicesDefault[];
  [FILTER_EDGE_SUPPORT_KEY]?: ChoicesDefault[];
  [FILTER_COOLING_KEY]?: ChoicesDefault[];
  [FILTER_GREEN_FEATURES_KEY]?: ChoicesDefault[];
  [FILTER_ADJUSTABLE_BASE_COMPATIBILITY_KEY]?: ChoicesDefault[];
  [FILTER_BACK_SUPPORT_KEY]?: FilterSleeperChoices;
  [FILTER_PRESSURE_RELIEF_KEY]?: FilterSleeperChoices;
  mattress_size?: ChoicesDefault[];
  reach?: ChoicesDefault[];
  return_policy?: ChoicesDefault[];
  [FILTER_MANUFACTURER_KEY]?: BrandFilter[];
};

export type FilterStatsType = {
  comfort_type: [boolean, number][];
  support_type: [boolean, number][];
  surface_type: [boolean, number][];
  brand_rating: [boolean, number][];
  match_score: [boolean, number][];
  has_promo: [boolean, number][];
  has_returns: [boolean, number][];
  free_delivery: [boolean, number][];
  factory_direct: [boolean, number][];
  bounce?: [boolean, number][];
  memory_feel?: [boolean, number][];
  all: number;
};

export type MattressBrandsFilterValues = {
  [FILTER_SURFACE_TYPE_KEY]: number[];
  [FILTER_COMFORT_TYPE_KEY]: number[];
  [FILTER_SUPPORT_TYPE_KEY]: number[];
  [FILTER_SOFTNESS_KEY]: number[];
  [FILTER_CUSHIONING_DEPTH_KEY]: number[];
  [FILTER_MEMORY_FEEL_KEY]: number[];
  [FILTER_BOUNCE_KEY]: number[];
  [FILTER_MOTION_ISOLATION_KEY]: number | null;
  [FILTER_EDGE_SUPPORT_KEY]: number | null;
  [FILTER_COOLING_KEY]: number | null;
  [FILTER_GREEN_FEATURES_KEY]: number | null;
  [FILTER_ADJUSTABLE_BASE_COMPATIBILITY_KEY]: number | null;
  [FILTER_RATING_KEY]?: number | null;
  zip_code?: string;
  distance?: number;
  mattress_size?: number;
  price?: string | number;
  p?: number[];
  [FILTER_STORE_RETAILER_KEY]?: string[];
  [FILTER_WARRANTY_LENGTH_KEY]?: number[];
  [FILTER_INDENTATION_COVERAGE_KEY]?: number[];
  [SORT_KEY]?: string;
};

export type MattressModelsFilterValues = {
  mattress_size?: number;
  price?: string | number;
  [FILTER_SURFACE_TYPE_KEY]: number[];
  [FILTER_COMFORT_TYPE_KEY]: number[];
  [FILTER_SUPPORT_TYPE_KEY]: number[];
  [FILTER_SOFTNESS_KEY]: number[];
  [FILTER_CUSHIONING_DEPTH_KEY]: number[];
  [FILTER_MEMORY_FEEL_KEY]: number[];
  [FILTER_BOUNCE_KEY]: number[];
  [FILTER_MOTION_ISOLATION_KEY]: number | null;
  [FILTER_EDGE_SUPPORT_KEY]: number | null;
  [FILTER_COOLING_KEY]: number | null;
  [FILTER_GREEN_FEATURES_KEY]: number | null;
  [FILTER_ADJUSTABLE_BASE_COMPATIBILITY_KEY]: number | null;
  [FILTER_BACK_SUPPORT_KEY]: FilterSleeperValue | null;
  [FILTER_PRESSURE_RELIEF_KEY]: FilterSleeperValue | null;
  return_policy?: number[];
  has_promo?: boolean;
  free_delivery?: boolean;
  financing_available?: boolean;
  [FILTER_WARRANTY_LENGTH_KEY]?: number[];
  [FILTER_INDENTATION_COVERAGE_KEY]?: number[];
  [SORT_KEY]?: string;
};

export type StoreBrandFilter = {
  name: string;
  value: string;
  children?: StoreBrandFilter[];
};

export type StoreRetailerFilter = {
  name: string;
  value: number;
};

export type StoreFilterChoices = {
  [FILTER_STORE_BRAND_KEY]: StoreBrandFilter[];
  [FILTER_STORE_RETAILER_KEY]: StoreRetailerFilter[];
};

export type CategoryKeys =
  | typeof FILTER_MEMORY_FEEL_KEY
  | typeof FILTER_BOUNCE_KEY
  | typeof FILTER_MOTION_ISOLATION_KEY
  | typeof FILTER_EDGE_SUPPORT_KEY
  | typeof FILTER_COOLING_KEY
  | typeof FILTER_GREEN_FEATURES_KEY
  | typeof FILTER_ADJUSTABLE_BASE_COMPATIBILITY_KEY
  | typeof FILTER_SURFACE_TYPE_KEY
  | typeof FILTER_COMFORT_TYPE_KEY
  | typeof FILTER_SUPPORT_TYPE_KEY
  | typeof FILTER_INDENTATION_COVERAGE_KEY
  | typeof FILTER_WARRANTY_LENGTH_KEY
  | typeof FILTER_BACK_SUPPORT_KEY
  | typeof FILTER_PRESSURE_RELIEF_KEY;

export type MatchedFiltersValue = string | number | number[] | boolean | FilterSleeperValue | null;

export type MatchedFilters = {
  key: CategoryKeys;
  value: MatchedFiltersValue;
}[];

export type MattressesFilterOnChangeValueType = string | number | boolean | undefined | null | FilterSleeperValue;

export interface Distance {
  value: number;
  unit: string;
}

export interface IconProps {
  color?: string;
  className?: string;
  width?: string;
  height?: string;
}

export enum ProductLayerTypes {
  COVER = 'Cover',
  COMFORT = 'Comfort Layers',
  TRANSITION = 'Transition Layers',
  SUPPORT = 'Support Layers',
}

export interface ProductLayer {
  layer_type: ProductLayerTypes;
  content?: string;
  description?: string;
  thickness: string | null;
}

export type MapStore = {
  name: string;
  slug: string;
  absolute_url: string;
  outbound_url: string;
  external_domain: string;
  city_st: string;
  map_position: {
    latitude: number;
    longitude: number;
  };
  street_address1: string;
  zip_code: string;
  has_ldp_status: string;
};

export interface FormControlProps {
  bsCustomPrefix?: string;
  htmlSize?: number;
  size?: 'sm' | 'lg';
  plaintext?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  value?: string | string[] | number;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>;
  custom?: boolean;
  type?: string;
  id?: string;
  isValid?: boolean;
  isInvalid?: boolean;
}

export enum ProductStatus {
  ACTIVE = 1,
  DISCONTINUED = 2,
}
