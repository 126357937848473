import React, { useEffect, useState } from 'react';
import qs from 'qs';
import has from 'lodash/has';
import DataLayerService from 'datalayer-service';
import {
  OnlinePromoAction,
  OnlinePromoEngagementType,
  PageRegion,
  PromoType,
  QualifyingEventAction,
} from 'datalayer-service/src/types/enums';
import { ProductType } from '../types/product-discount';
import { Promotion } from '../types/promotion';
import fetch from '../utils/fetchService';
import { getQualifyingActionEventData } from '../utils/dataLayer';
import { User } from '../types/user';
import { scrollToHeight } from '../utils/commons';

type PromoDealsParams = {
  'show-promo-code': number;
  classname: string;
  slug: string;
  is_cp: string;
  cashback_promo: number;
};

interface ProductDiscountModalDatalayerParams {
  pageRegion?: PageRegion;
  promoType?: PromoType;
  engagementType?: OnlinePromoEngagementType;
}

export type ProductDiscountModalHookType = {
  object: ProductType;
  showDiscountModal: boolean;
  setShowDiscountModal: React.Dispatch<React.SetStateAction<boolean>>;
};

// onShowDiscountModal is used to manually controlled the discount modal. This was done to resolve
// a bug in product lists (mattress-deals, mattress-brands/local/, mattress-models/online/, mattress-reviews)
// that when you triggered the infinite loader, when /promos/mattress-deals/ request finished it wasn't openning
// the ProductDiscountModal.
export default function useProductDiscountModal(
  userData: User | null,
  item?: ProductType,
  onShowDiscountModal?: ((slug: string, promotion: Promotion, data: any) => void) | undefined,
  datalayerParams?: ProductDiscountModalDatalayerParams,
): ProductDiscountModalHookType {
  const [data, setData] = useState<ProductType>(item);
  const [showDiscountModal, setShowDiscountModal] = useState<boolean>(false);

  const removeExtraUrlParams = (): void => {
    const { href } = window.location;
    const idx = href.indexOf('show-promo-code');
    window.history.pushState({}, '', href.substring(0, idx - 1));
  };

  const handleShowDiscountModal = (slug: string, response: any): void => {
    const dataLayerService = new DataLayerService(window);
    const currentPromo = response.extra_data.promotion || response.extra_data.cashback_promotion;

    dataLayerService.onlinePromotionInteractionEvent({
      action: OnlinePromoAction.PROMO_REVEAL,
      type: datalayerParams?.promoType || PromoType.ONLINE,
      retailer: currentPromo.retailer.name,
      pageRegion: datalayerParams?.pageRegion || PageRegion.PRODUCTS,
      engagementType: datalayerParams?.engagementType || OnlinePromoEngagementType.PRODUCT,
    });

    if (userData)
      dataLayerService.qualifyingActionEvent(
        getQualifyingActionEventData(QualifyingEventAction.PROMOTION_CLICK, userData),
      );

    if (onShowDiscountModal) {
      onShowDiscountModal(slug, currentPromo, response);
    } else {
      setShowDiscountModal(true);
    }
  };

  function getPromoDeals(params: PromoDealsParams): void {
    const url = `/promos/mattress-deals/${params['show-promo-code']}`;

    const args: any = {
      classname: params.classname,
      slug: params.slug,
    };
    if (params.is_cp) {
      args.is_cp = true;
    }
    if (params.cashback_promo) {
      args.cashback_promo = params.cashback_promo;
    }

    fetch
      .get(url, args)
      .then((response: any) => {
        setData(response);
        handleShowDiscountModal(params.slug, response);
      })
      .catch((e: any) => {
        console.error('Error fetching mattress deals promos', e);
      });
  }

  useEffect(() => {
    const search = window.location.search.substring(1, window.location.search.length);
    const params: any = qs.parse(search);

    const hasParams: boolean = has(params, 'show-promo-code') && has(params, 'classname') && has(params, 'slug');
    const { screen_pos: screenPos } = params;
    const screenHeight = Number(screenPos);

    if (hasParams && !showDiscountModal) {
      removeExtraUrlParams();
      getPromoDeals(params);

      if (screenHeight) {
        scrollToHeight(screenHeight);
      }
    }
  }, []);

  return {
    object: data,
    showDiscountModal,
    setShowDiscountModal,
  };
}
