import React, { FC } from 'react';
import { CTAClickCategory, CTAClickLevel, PageRegion } from 'datalayer-service/src/types/enums';
import ReactPlaceholder from 'react-placeholder';
import LinkButton from '../../atoms/LinkButton';
import { Brand } from '../../../types/brands';
import { GoodBedReview } from '../../../types/commons';
import SmoothChevronIcon from '../../atoms/Icons/SmoothChevron';
import CustomersRating from '../Product/CustomerRating';
import { getReviewsUrl } from '../../../utils/urls';
import { getProductEventDataWithMattressHierarchy } from '../../../utils/dataLayer';
import useBreakPoints from '../../../hooks/useBreakPoints';
import MediaModal, { MediaElement } from '../MediaModal';
import styles from './EditorialReviewModal.module.scss';
import useFetch from '../../../hooks/useFetch';

export const EDITORIAL_REVIEW_MODAL_ID = 'editorial-review-modal';

export interface EditorialReviewModalProps {
  show: boolean;
  slug: string;
  classname: string;
  onHide: () => void;
  unmount?: () => void; // this for Django React component
  initialIndex?: number;
  shouldCacheData?: boolean;
  isHomePage?: boolean;
}

interface EditorialReviewsPayload {
  goodbed_reviews?: GoodBedReview[];
}

const REVIEWS_ENDPOINT = 'goodbed_reviews';

const EditorialReviewModal: FC<EditorialReviewModalProps> = ({
  show,
  slug,
  classname,
  onHide,
  unmount,
  shouldCacheData,
  initialIndex = 0,
  isHomePage = false,
}) => {
  const { isMediumScreenSize } = useBreakPoints();

  const { data: brand } = useFetch<Brand>(
    `${classname}/${slug}/`,
    {},
    {
      shouldCache: shouldCacheData,
      cacheKey: `${slug}-product-editorial-review-modal`,
    },
  );

  const { data: reviewsData } = useFetch<EditorialReviewsPayload>(
    `${classname}/${slug}/${REVIEWS_ENDPOINT}/`,
    {},
    {
      shouldCache: shouldCacheData,
      cacheKey: `${slug}-${REVIEWS_ENDPOINT}-editorial-review-modal`,
    },
  );

  const reviews = reviewsData?.goodbed_reviews || [];

  const ready = !!brand && !!reviews.length;

  const getRightContent = (currentMediaIndex: number): JSX.Element => {
    const currentReviewTitle = reviews.length ? reviews[currentMediaIndex].title : '';

    return (
      <div className={styles.textContent}>
        <ReactPlaceholder
          ready={ready}
          rows={2}
          type="text"
          style={{ width: isMediumScreenSize ? '80%' : '300px', height: '65px', margin: 'auto' }}
          showLoadingAnimation
        >
          <div className={styles.goodbedReviewContainerTitleContainer}>
            <p className={styles.title}>{brand?.name || ''}</p>
            <p className={styles.subtitle}>
              <a href={reviews[currentMediaIndex]?.url}>{currentReviewTitle}</a>
            </p>
          </div>
        </ReactPlaceholder>

        <ReactPlaceholder
          ready={ready}
          type="textRow"
          style={{ width: isMediumScreenSize ? '85%' : '300px', height: '100px', margin: 'auto', marginTop: '20px' }}
          showLoadingAnimation
        >
          <div className={styles.goodbedReviewContainer}>
            <p className={styles.goodbedReviewContainerTitle}>GoodBed Expert Reviews</p>
            <p className={styles.goodbedReviewContainerSubtitle}>
              {reviews.length} reviews of {brand?.name || ''} products
            </p>

            {ready ? (
              <LinkButton
                ctaData={{
                  pageRegion: PageRegion.EDITORIAL_REVIEW_MODAL,
                  category: CTAClickCategory.SEE_MORE_REVIEWS,
                  level: CTAClickLevel.SECONDARY,
                  product: getProductEventDataWithMattressHierarchy(brand),
                }}
                ariaLabel={`see ${brand?.name || ''} reviews`}
                to={isHomePage ? `${brand?.absolute_url}#expert-reviews` : reviews[currentMediaIndex].url}
                className={styles.goodbedReviewContainerCta}
                useRouter={false}
              >
                <b>See GoodBed Reviews</b>
                <p>of {brand?.name || ''} products</p>
              </LinkButton>
            ) : null}
          </div>
        </ReactPlaceholder>

        <ReactPlaceholder
          ready={ready}
          type="textRow"
          style={{ width: isMediumScreenSize ? '85%' : '300px', height: '160px', margin: '25px auto auto auto' }}
          showLoadingAnimation
        >
          {ready ? (
            <div className={styles.customerRatingContainer}>
              <p>Customer Rating</p>
              <CustomersRating
                type={brand.classname}
                slug={brand.slug}
                ratingCount={brand.brand_rating.rating_count}
                customersRating={brand.brand_rating.goodbed_rating}
                ratingDisplayText={brand.brand_rating.goodbed_rating_display}
                shouldUseRatingsLink={false}
              />

              <a href={getReviewsUrl(brand.classname, brand.slug, brand.brand_rating.rating_count)}>
                See Customer Reviews <SmoothChevronIcon rotate={180} />
              </a>
            </div>
          ) : null}
        </ReactPlaceholder>
      </div>
    );
  };

  const media: MediaElement[] = reviews.map(({ youtube_id, title }) => ({
    title,
    alt: title,
    youtubeId: youtube_id,
    isVideo: true,
  }));

  return (
    <MediaModal
      show={show}
      media={media}
      testId={EDITORIAL_REVIEW_MODAL_ID}
      initialIndex={initialIndex}
      isLoading={!ready}
      rightContent={getRightContent}
      className={styles.editorialReviewModal}
      onHide={(): void => {
        if (unmount) unmount();

        onHide();
      }}
      title={brand?.name}
    />
  );
};

export default EditorialReviewModal;
