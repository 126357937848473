import get from 'lodash/get';
import has from 'lodash/has';
import {
  AccountAuthenticationEvent,
  BaseProduct,
  BaseUserData,
  Location,
  Product as ProductEventData,
  QualifyingActionEvent,
  QuizAnswer,
  StoreLocatorResult,
} from 'datalayer-service/src/types';
import {
  AccountAuthenticationAction,
  AccountAuthenticationLevel,
  AccountAuthenticationUserType,
  PageSubType,
  QualifyingEventAction,
  QuizInteractionAnswerTryInStore,
  QuizInteractionSaveMethod,
} from 'datalayer-service/src/types/enums';
import { EMPTY_EVENT_PRODUCT, QUIZ_INTERACTION_EVENT } from 'datalayer-service/src/event-objects';
import { SEND_EMAIL, SEND_TEXT } from '../constants/signin';
import { stringReplaceAll, wait } from './commons';
import { COLLECTION, LINE, MANUFACTURER, MATTRESS_HIERARCHY_CLASSNAMES, MODEL } from '../constants/commons';
import { Breadcrumbs, BreadcrumbsItem, MattressChoices, MattressStatus } from '../types/commons';
import { EditorialRatingProduct } from '../types/mattress-comparison';
import { MatchQuizValues } from '../types/mattress-quiz';
import { MatchResults } from '../types/mattress-results';
import { Store } from '../types/stores';
import { ShareResults } from '../types/signin';
import { UpdateUserFn, User } from '../types/user';
import { Manufacturer } from '../types/manufacturer';
import { MattressLine } from '../types/mattress-line';
import { MattressModel, WithMattressHierarchy, WithMattressHierarchyNames } from '../types/mattresses';

interface BrandObject {
  manufacturer?: Manufacturer;
  line?: MattressLine;
  mattresscollection?: any;
  mattress?: MattressModel;
  name: string;
  slug: string;
  classname: string;
}

type MattressClassnames = 'manufacturer' | 'line' | 'mattresscollection' | 'mattress';

const MEMBER_CREATION_DELAY = 2500;

const getTryInStoreValue = (key: string, values: MatchQuizValues | MatchResults): QuizInteractionAnswerTryInStore => {
  const redeem = get(values, key, 0);

  if (redeem === 0) return QuizInteractionAnswerTryInStore.EMPTY;

  return redeem === 2 ? QuizInteractionAnswerTryInStore.YES : QuizInteractionAnswerTryInStore.NO;
};

const getClassnameProductSlug = (brand: BrandObject, classname: MattressClassnames): string => {
  if (!brand) return '';

  if (classname === brand.classname) return brand.slug;

  if (brand[classname]) {
    return brand[classname].slug as string;
  }

  return '';
};

export const getProductHierarchyName = (
  brand: BrandObject,
): Pick<ProductEventData, 'collection' | 'line' | 'manufacturer'> => {
  const hierarchyData: BaseProduct = {};

  const classnamesKeys = Object.keys(MATTRESS_HIERARCHY_CLASSNAMES) as Array<
    keyof typeof MATTRESS_HIERARCHY_CLASSNAMES
  >;

  classnamesKeys.forEach((classname) => {
    const classnameName = get(MATTRESS_HIERARCHY_CLASSNAMES, classname, MANUFACTURER);
    const classnameProductSlug = getClassnameProductSlug(brand, classname);

    (hierarchyData as any)[classnameName] = classnameProductSlug;
  });

  return hierarchyData;
};

export interface ReviewRatingProduct {
  breadcrumbs?: Breadcrumbs;
  listed: boolean;
  get_status_display: MattressStatus;
}

export const getReviewRatingsProductEventData = (product: ReviewRatingProduct): ProductEventData => {
  const { breadcrumbs } = product;

  const eventData: ProductEventData = {
    manufacturer: breadcrumbs && has(breadcrumbs, MANUFACTURER) ? breadcrumbs[MANUFACTURER].slug : '',
    listed: product.listed ? 'yes' : 'no',
    status: product?.get_status_display === 'Active' ? 'active' : 'discounted',
  };

  const breadcrumbsHierarchy = Object.entries<BreadcrumbsItem>(breadcrumbs || {});

  breadcrumbsHierarchy.forEach(([hierarchyLevel, value]) => {
    const classname = get(MATTRESS_HIERARCHY_CLASSNAMES, hierarchyLevel, MANUFACTURER);
    eventData[classname] = value.slug;
  });

  return eventData;
};

export const getProductEventDataWithHierarchyNames = (product: WithMattressHierarchyNames): BaseProduct => {
  const eventData: BaseProduct = {};

  if (product.manufacturer_name) eventData.manufacturer = product.manufacturer_name;
  if (product.line_name) eventData.line = product.line_name;
  if (product.model_name) eventData.model = product.model_name;
  if (product.collection_name) eventData.collection = product.collection_name;

  return eventData;
};

export const getProductEventDataWithHierarchyObj = (product: EditorialRatingProduct): BaseProduct => {
  const eventData: BaseProduct = {};

  if (!has(product, 'hierarchy')) return EMPTY_EVENT_PRODUCT;

  if (product.hierarchy[MANUFACTURER]) eventData[MANUFACTURER] = product.hierarchy[MANUFACTURER].slug;
  if (product.hierarchy[LINE]) eventData[LINE] = product.hierarchy[LINE].slug;
  if (product.hierarchy[COLLECTION]) eventData[COLLECTION] = product.hierarchy[COLLECTION].slug;
  if (product.hierarchy[MODEL]) eventData[MODEL] = product.hierarchy[MODEL].slug;

  return eventData;
};

export const getProductEventDataWithMattressHierarchy = (
  product: WithMattressHierarchy,
  isEditorialProduct = false,
): ProductEventData => {
  const productHierarchy = isEditorialProduct
    ? getProductEventDataWithHierarchyObj(product as EditorialRatingProduct)
    : getProductHierarchyName(product);

  return {
    listed: product.listed ? 'yes' : 'no',
    status: product.get_status_display === 'Active' ? 'active' : 'discounted',
    ...productHierarchy,
  };
};

export const getPageSubTypeFromClassName = (className: string): PageSubType => {
  const subtypeClass = get(PageSubType, className.toUpperCase(), '');

  if (subtypeClass) return subtypeClass;

  return PageSubType.BRANDS;
};

export const getStoreEventData = (store: Store): Location => {
  return {
    isLdp: has(store, 'has_ldp_status') && store.has_ldp_status ? 'yes' : 'no',
    retailerLocation: store.slug ? stringReplaceAll(store.slug, '-', ' ') : '',
    retailerName: store.retailer.name,
  };
};

export const getStoreLocatorEventData = (store: Location, position: number): StoreLocatorResult => {
  return {
    ...store,
    position: position + 1,
  };
};

export const getLdpCount = (arr: Store[]): number => {
  const filteredArr = arr.filter((a) => a.has_ldp_status);

  return filteredArr.length || 0;
};

export const getDatalayerQuizAnswers = (
  values: MatchQuizValues | MatchResults,
  choices?: MattressChoices,
): QuizAnswer => {
  let { age, recipient, size } = QUIZ_INTERACTION_EVENT.answers;

  if (choices) {
    // MATTRESS QUIZ FINDER
    const ageValue = get(values, 'age', undefined);
    const targetPerson = get(values, 'target_person', undefined);
    const mattressSize = get(values, 'mattress_size', undefined);

    if (ageValue) age = choices?.age.find((t) => t.value === ageValue)?.name || QUIZ_INTERACTION_EVENT.answers.age;
    if (targetPerson)
      recipient =
        choices?.target_person.find((t) => t.value.toString() === targetPerson)?.name ||
        QUIZ_INTERACTION_EVENT.answers.recipient;
    if (mattressSize)
      size =
        choices?.mattress_size.find((s) => s.value.toString() === mattressSize)?.name ||
        QUIZ_INTERACTION_EVENT.answers.size;
  } else {
    // MATTRESS QUIZ RESULTS

    age = get(values, 'profile.age.name', QUIZ_INTERACTION_EVENT.answers.age);
    size = get(values, 'meta.mattress_size.name', QUIZ_INTERACTION_EVENT.answers.size);
    recipient = get(values, 'target_person.name', QUIZ_INTERACTION_EVENT.answers.age);
  }

  const tryInStore = getTryInStoreValue(choices ? 'redeem' : 'meta.redeem', values);
  const budgetRange: string | number = get(
    values,
    choices ? 'price' : 'meta.price',
    QUIZ_INTERACTION_EVENT.answers.budgetRange,
  );

  return {
    age,
    budgetRange: choices ? budgetRange : parseInt(budgetRange.toString(), 10), // price is a string for quiz results.
    deliveryZip: get(values, 'zip_code', QUIZ_INTERACTION_EVENT.answers.deliveryZip),
    recipient,
    size,
    tryInStore,
  };
};

export const getDataLayerQuizSaveMethod = (values: ShareResults): QuizInteractionSaveMethod => {
  const hasSendEmail = has(values, SEND_EMAIL);
  const sendEmail = hasSendEmail ? values[SEND_EMAIL] : false;
  const hasSendText = has(values, SEND_TEXT);
  const sendText = hasSendText ? values[SEND_TEXT] : false;
  let saveMethod = QuizInteractionSaveMethod.BOTH;

  if (sendEmail && sendText) saveMethod = QuizInteractionSaveMethod.BOTH;
  else if (sendEmail) saveMethod = QuizInteractionSaveMethod.EMAIL;
  else if (sendText) saveMethod = QuizInteractionSaveMethod.TEXT;

  return saveMethod;
};

export const getUserEventData = (user: User): BaseUserData => {
  const memberId = `${user.profile?.id || ''}`;
  let authLevel: AccountAuthenticationLevel;
  if (user.is_superuser) authLevel = AccountAuthenticationLevel.SUPER_ADMIN;
  else if (user.is_staff) authLevel = AccountAuthenticationLevel.STAFF;
  else authLevel = AccountAuthenticationLevel.REGULAR;

  const userType = memberId ? AccountAuthenticationUserType.MEMBER : AccountAuthenticationUserType.ACCOUNT_HOLDER;

  return {
    authLevel,
    userType,
    memberId,
  };
};

export const getUserAuthEventData = (action: AccountAuthenticationAction, user: User): AccountAuthenticationEvent => {
  return {
    ...getUserEventData(user),
    action,
  };
};

export const getQualifyingActionEventData = (action: QualifyingEventAction, user: User): QualifyingActionEvent => {
  return {
    ...getUserEventData(user),
    action,
  };
};

export const onQualifyingActionClickEvent = async (updateUser: UpdateUserFn, dataLayerService: any): Promise<void> => {
  await wait(MEMBER_CREATION_DELAY);
  const updatedUserData = await updateUser();

  if (updatedUserData)
    dataLayerService.qualifyingActionEvent(
      getQualifyingActionEventData(QualifyingEventAction.OUTBOUND_LINK, updatedUserData),
    );
};
