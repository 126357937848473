/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';

const ProductDiscountBoxFoldIcon: FC<IconProps> = ({ className = 'fold-icon' }) => (
  <svg className={className} width="35" height="37" viewBox="0 0 35 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      id="Vector 22"
      d="M0.708374 0.00574848L7.7934 29.0657C8.15058 30.5307 9.30129 31.6704 10.7697 32.0134L29.8772 36.4769C35.0203 37.0759 34.2573 36.8849 34.1939 35.3202L0.708374 0.00574848Z"
      fill="#9BAFD5"
    />
  </svg>
);

export default ProductDiscountBoxFoldIcon;
