import { useEffect, useState } from 'react';
import useWindowWidth from './useWindowWidth';

export type BreakPointResult = {
  isDesktop: boolean;
  isMediumScreenSize: boolean;
  isXSSmallScreenSize: boolean;
  isXSmallScreenSize: boolean;
  isXSSSmallScreenSize: boolean;
  isMiniScreenSize: boolean;
  isMobile: boolean;
};

export const DEFAULT_BREAKPOINTS: BreakPointResult = {
  isDesktop: false,
  isMediumScreenSize: false,
  isXSSmallScreenSize: false,
  isXSmallScreenSize: false,
  isXSSSmallScreenSize: false,
  isMiniScreenSize: false,
  isMobile: false,
};

export const semiLargeScreenSize = 1024;
const mediumScreenSize = 768;
const smallScreenSize = 576;
const xSmallScreenSize = 460;
const xSSmallScreenSize = 420;
const xSSSmallScreenSize = 390;
const miniScreenSize = 375;

export default function useBreakPoints(): BreakPointResult {
  const width = useWindowWidth();

  const getBreakPoint = (currentWidth: number): BreakPointResult => {
    return {
      isDesktop: currentWidth > semiLargeScreenSize,
      isMediumScreenSize: currentWidth <= mediumScreenSize,
      isMobile: currentWidth <= smallScreenSize,
      isXSmallScreenSize: currentWidth <= xSmallScreenSize,
      isXSSmallScreenSize: currentWidth <= xSSmallScreenSize,
      isXSSSmallScreenSize: currentWidth <= xSSSmallScreenSize,
      isMiniScreenSize: currentWidth <= miniScreenSize,
    };
  };

  const [result, setResult] = useState(getBreakPoint(semiLargeScreenSize + 1));

  useEffect((): void => {
    setResult(getBreakPoint(width));
  }, [width]);
  return result;
}
