import React, { memo } from 'react';
import cls from 'classnames';
import DataLayerService from 'datalayer-service';
import { CTAClick } from 'datalayer-service/src/types';
import { openProductDiscountOutboundUrl } from '../../../utils/promos';
import { PromotionType } from '../../../types/promotion';

type NewPromoButtonProps = {
  className?: string;
  promotionType: PromotionType;
  // required if PromotionType.AFFILIATE_REBATE
  mfrName: string;
  // required if PromotionType.LOCAL_REBATE
  retailerName: string;
  // id, classname and slug are required if isClaimable = false
  id?: number;
  classname?: string;
  slug?: string;
  outboundURL?: string;
  isClaimable?: boolean;
  hideSubtitle?: boolean;
  ctaData: CTAClick;
  onClick?: () => void;
};

const NewPromoButton: React.FunctionComponent<NewPromoButtonProps> = ({
  className,
  promotionType,
  mfrName,
  retailerName,
  id,
  classname,
  slug,
  outboundURL,
  isClaimable = false,
  hideSubtitle = false,
  ctaData,
  onClick,
}: NewPromoButtonProps) => {
  const handleClick = (): void => {
    const dataLayerService = new DataLayerService(window);

    dataLayerService.CTAClickEvent(ctaData);

    if (onClick) onClick();
    else if (!isClaimable && id && classname && slug) openProductDiscountOutboundUrl(id, classname, slug, outboundURL);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <a 
        className={cls('btn-new-promotion', className, {
          'no-subtitle': hideSubtitle,
        })}
        rel="nofollow"
        onClick={handleClick}
      >
        <b>GET DEAL</b>
        {(mfrName || retailerName) && !hideSubtitle && (
          <div className="btn-new-promotion-sm-text">
            {promotionType === PromotionType.AFFILIATE_REBATE ? `for ${mfrName}` : `at ${retailerName}`}
          </div>
        )}
      </a>
    </>
  );
};

export default memo(NewPromoButton);
