/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';
import { ICON_CLASS } from '../../../constants/commons';

interface ChevronIconProps extends IconProps {
  rotate?: number;
}

const SmoothChevronIcon: FC<ChevronIconProps> = ({ className = '', color = 'white', rotate = 0 }) => (
  <svg
    className={`${ICON_CLASS} smooth-chevron-icon ${className}`}
    viewBox="0 0 8 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ transform: rotate ? `rotate(${rotate}deg)` : undefined }}
    width="24"
    height="24"
  >
    <path d="M6 10L2 6L6 2" stroke={color} strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default SmoothChevronIcon;
