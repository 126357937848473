'use client';

import React, { FC, HTMLAttributeAnchorTarget, LegacyRef, useRef } from 'react';
import isEmpty from 'lodash/isEmpty';
import cls from 'classnames';
import { getProductBaseURL, getReviewsUrl } from '../../../../utils/urls';
import SmoothStarIcon from '../../../atoms/Icons/SmoothStar';
import Tooltip from '../../../atoms/Tooltip';
import { numberWithThousands } from '../../../../utils/commons';
import { MIN_RATING_COUNT_TO_RATINGS_LIST } from '../../../../constants/mattress-compare';

interface CustomerRatingProps {
  className?: string;
  type?: string;
  slug?: string;
  customersRating: number;
  ratingCount?: number;
  ratingDisplayText?: string;
  shouldUseRatingsLink?: boolean;
  ratingLinkURL?: string;
  direction?: 'horizontal' | 'vertical';
  theme?: 'normal' | 'light';
  wrapLinks?: boolean;
  ratingLinkTarget?: HTMLAttributeAnchorTarget;
  withTooltip?: boolean;
  description?: string;
  subDescription?: string;
  ratingInherited?: boolean;
  showRatingNumberBase?: boolean;
}

const CUSTOMER_RATING_TEXT = 'Customer Rating';

const CustomersRating: FC<CustomerRatingProps> = ({
  className = '',
  customersRating = 0,
  ratingCount = 0,
  ratingDisplayText = '',
  shouldUseRatingsLink = true,
  direction = 'horizontal',
  subDescription,
  wrapLinks = false,
  theme = 'normal',
  ratingLinkTarget = '_blank',
  withTooltip,
  ratingLinkURL,
  description,
  type,
  slug,
  ratingInherited = false,
  showRatingNumberBase = false,
}) => {
  const tooltipContainer = useRef<HTMLElement>(null);
  const boxText = customersRating || 'N/A';
  const descriptionText = customersRating ? ratingDisplayText || CUSTOMER_RATING_TEXT : 'Not rated yet';

  const getLinkText = (): JSX.Element => {
    const baseURL = type ? getProductBaseURL(type) : null;

    const hasRating = ratingCount > 0;
    const rating = numberWithThousands(ratingCount || 0, 5);
    const showRatingsURL = ratingCount >= MIN_RATING_COUNT_TO_RATINGS_LIST && shouldUseRatingsLink && slug && type;
    const ratingsPageURL =
      showRatingsURL && !isEmpty(baseURL) && !isEmpty(slug) ? getReviewsUrl(type, slug, ratingCount) : '';

    const rText = subDescription || (ratingInherited ? `Read ${rating} brand reviews` : `${rating} ratings`);

    return (
      <div
        className={cls('customer-rating__link-text-container', {
          'wrap-links': wrapLinks,
        })}
      >
        {hasRating && showRatingsURL && (
          <a
            href={ratingLinkURL || ratingsPageURL}
            className={cls('link', {
              'has-link': showRatingsURL,
            })}
            rel="noreferrer"
            target={ratingLinkTarget}
          >
            {rText}
          </a>
        )}

        {hasRating && !showRatingsURL && <span>{rText}</span>}

        {!hasRating ? (
          <a
            href={`/review-mattress/${type}/${slug}/`}
            className={cls('link has-link')}
            rel="noreferrer"
            target="_blank"
          >
            Write a review
          </a>
        ) : null}
      </div>
    );
  };

  const getRatingBox = (): JSX.Element => (
    <div className="customer-rating__box">
      {customersRating ? <SmoothStarIcon className="customer-rating__box__star" /> : null}
      {boxText}
      {showRatingNumberBase ? <span>/100</span> : null}
    </div>
  );

  const getTooltipContent = (): JSX.Element => (
    <div
      dangerouslySetInnerHTML={{
        __html: !isEmpty(description) ? description! : '',
      }}
    />
  );

  return (
    <div
      className={cls('customer-rating', className, direction, theme, {
        'no-data': !customersRating,
      })}
      ref={tooltipContainer as LegacyRef<HTMLDivElement>}
    >
      {withTooltip && description ? (
        <Tooltip
          id="customer-rating-tooltip"
          container={tooltipContainer.current || undefined}
          content={getTooltipContent()}
        >
          {getRatingBox()}
        </Tooltip>
      ) : (
        getRatingBox()
      )}
      <div className="customer-rating__description">
        {withTooltip ? CUSTOMER_RATING_TEXT : descriptionText}
        {getLinkText()}
      </div>
    </div>
  );
};

export default CustomersRating;
