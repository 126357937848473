/* eslint-disable max-len */
import React, { FC } from 'react';
import { IconProps } from '../../../types/commons';
import { ICON_CLASS } from '../../../constants/commons';

const VideoPlayerResumeIcon: FC<IconProps> = ({ className = '' }) => (
  <svg
    className={`${ICON_CLASS} ${className} video-player-resume-icon`}
    width="33"
    height="37"
    viewBox="0 0 33 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.5 15.2947C33.1667 16.8343 33.1667 20.6833 30.5 22.2229L6.5 36.0793C3.83333 37.6189 0.499998 35.6944 0.499998 32.6152L0.5 4.90238C0.5 1.82318 3.83333 -0.10132 6.5 1.43828L30.5 15.2947Z"
      fill="#4561A4"
    />
  </svg>
);

export default VideoPlayerResumeIcon;
