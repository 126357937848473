import { ChoicesDefault } from '../types/commons';
import { NumericalString } from '../types/utils';

export const FILTER_PRICE_SEPARATOR = ',';

export const FILTER_DISTANCE_LOCATION_KEY = 'distanceLocation';
export const FILTER_PRICE_KEY = 'price';
export const FILTER_SIZE_KEY = 'mattress_size';
export const FILTER_FEATURES_KEY = 'features';
export const FILTER_COMFORT_FEEL_KEY = 'comfortFeel';
export const FILTER_PERSONAL_MATCH_SCORE_KEY = 'personalMatchScore';
export const FILTER_MATTRESS_TYPE_KEY = 'mattressType';
export const FILTER_WARRANTY_KEY = 'warranty';
export const FILTER_RETURN_POLICY_KEY = 'return_policy';
export const FILTER_DISCOUNT_AVAILABLE_KEY = 'has_promo';
export const FILTER_FREE_DELIVERY_KEY = 'free_delivery';
export const FILTER_FINANCING_AVAILABLE_KEY = 'financing_available';
export const FILTER_STORE_BRAND_KEY = 'p';
export const FILTER_STORE_RETAILER_KEY = 'retailer';
export const FILTER_BRAND_KEY = 'brand';
export const FILTER_SURFACE_TYPE_KEY = 'surface_type';
export const FILTER_COMFORT_TYPE_KEY = 'comfort_type';
export const FILTER_SUPPORT_TYPE_KEY = 'support_type';
export const FILTER_URL_PARAMS_SUPPORT_TYPE_KEY = 'support-type';
export const FILTER_WARRANTY_LENGTH_KEY = 'warranty_length';
export const FILTER_INDENTATION_COVERAGE_KEY = 'indentation_coverage';
export const FILTER_BOUNCE_KEY = 'bounce';
export const FILTER_MEMORY_FEEL_KEY = 'memory_feel';
export const FILTER_CUSHIONING_DEPTH_KEY = 'cushioning';
export const FILTER_SOFTNESS_KEY = 'softness';
export const FILTER_MOTION_ISOLATION_KEY = 'motion_isolation';
export const FILTER_EDGE_SUPPORT_KEY = 'edge_support';
export const FILTER_COOLING_KEY = 'cooling';
export const FILTER_GREEN_FEATURES_KEY = 'green_features';
export const FILTER_ADJUSTABLE_BASE_COMPATIBILITY_KEY = 'adjustable_base_compatibility';
export const FILTER_SEX_KEY = 'sex';
export const FILTER_SLEEP_POSITION_KEY = 'sleep_position';
export const FILTER_WEIGHT_KEY = 'weight';
export const FILTER_AGE_KEY = 'age';
export const FILTER_CONDITION_TAGS_KEY = 'condition_tags';
export const FILTER_TARGET_PERSON_KEY = 'target_person';
export const FILTER_RATING_KEY = 'rating';
export const FILTER_CUSTOMER_RATING_KEY = 'customer_rating';
export const FILTER_REVIEW_TYPE_KEY = 'review_type';
export const FILTER_DATE_PURCHASED_RANGES_KEY = 'date_purchased_ranges';
export const FILTER_URL_PARAMS_MANUFACTURER_KEY = 'm';
export const FILTER_URL_PARAMS_LINE_KEY = 'l';
export const FILTER_URL_PARAMS_COLLECTION_KEY = 'c';
export const FILTER_URL_PARAMS_MATCH_SCORE = 'match_score';
export const SORT_KEY = 'sort_by';
export const FILTER_PAGE_KEY = 'page';
export const FILTER_MANUFACTURER_KEY = 'manufacturer';
export const FILTER_BACK_SUPPORT_KEY = 'back_support';
export const FILTER_PRESSURE_RELIEF_KEY = 'pressure_relief';

export const FILTER_SLEEPER_ONE_KEY = 'sleeper_one';
export const FILTER_SLEEPER_TWO_KEY = 'sleeper_two';

export const FILTER_SLEEP_POSITION_URL_PARAMS_KEY = 'sp';
export const FILTER_WEIGHT_URL_PARAMS_KEY = 'w';

export const FILTER_BACK_SUPPORT_URL_PARAMS_PREFIX = 'support';
export const FILTER_PRESSURE_RELIEF_URL_PARAMS_PREFIX = 'pressure';

export const BACK_SUPPORT_PREFIX_LABEL = 'Support';
export const PRESSURE_RELIEF_PREFIX_LABEL = 'Pressure Relief';

export const SLEEPER = {
  [FILTER_SLEEPER_ONE_KEY]: {
    key: FILTER_SLEEPER_ONE_KEY,
    label: 'Sleeper 1',
  },
  [FILTER_SLEEPER_TWO_KEY]: {
    key: FILTER_SLEEPER_TWO_KEY,
    label: 'Sleeper 2',
  },
};

export const SLEEPER_FILTER: any = {
  [FILTER_BACK_SUPPORT_KEY]: {
    key: FILTER_BACK_SUPPORT_KEY,
    prefix: BACK_SUPPORT_PREFIX_LABEL,
    urlParamPrefix: FILTER_BACK_SUPPORT_URL_PARAMS_PREFIX,
    editorialRatingKey: 'spine_align',
    [FILTER_SLEEPER_ONE_KEY]: {
      key: `${FILTER_BACK_SUPPORT_KEY}-${FILTER_SLEEPER_ONE_KEY}`,
      label: `${BACK_SUPPORT_PREFIX_LABEL} - ${SLEEPER[FILTER_SLEEPER_ONE_KEY].label}`,
    },
    [FILTER_SLEEPER_TWO_KEY]: {
      key: `${FILTER_BACK_SUPPORT_KEY}-${FILTER_SLEEPER_TWO_KEY}`,
      label: `${BACK_SUPPORT_PREFIX_LABEL} - ${SLEEPER[FILTER_SLEEPER_TWO_KEY].label}`,
    },
  },
  [FILTER_PRESSURE_RELIEF_KEY]: {
    key: FILTER_PRESSURE_RELIEF_KEY,
    prefix: PRESSURE_RELIEF_PREFIX_LABEL,
    urlParamPrefix: FILTER_PRESSURE_RELIEF_URL_PARAMS_PREFIX,
    editorialRatingKey: `${FILTER_PRESSURE_RELIEF_KEY}.press_relief_typ_curves`,
    [FILTER_SLEEPER_ONE_KEY]: {
      key: `${FILTER_PRESSURE_RELIEF_KEY}-${FILTER_SLEEPER_ONE_KEY}`,
      label: `${PRESSURE_RELIEF_PREFIX_LABEL} - ${SLEEPER[FILTER_SLEEPER_ONE_KEY].label}`,
    },
    [FILTER_SLEEPER_TWO_KEY]: {
      key: `${FILTER_PRESSURE_RELIEF_KEY}-${FILTER_SLEEPER_TWO_KEY}`,
      label: `${PRESSURE_RELIEF_PREFIX_LABEL} - ${SLEEPER[FILTER_SLEEPER_TWO_KEY].label}`,
    },
  },
};

export const SLEEPER_FILTERS = [FILTER_BACK_SUPPORT_KEY, FILTER_PRESSURE_RELIEF_KEY];

// Filters that are array;
export const ARRAY_FILTERS = [
  FILTER_STORE_BRAND_KEY,
  FILTER_STORE_RETAILER_KEY,
  FILTER_BRAND_KEY,
  FILTER_MANUFACTURER_KEY,
  FILTER_SURFACE_TYPE_KEY,
  FILTER_COMFORT_TYPE_KEY,
  FILTER_SUPPORT_TYPE_KEY,
  FILTER_WARRANTY_LENGTH_KEY,
  FILTER_INDENTATION_COVERAGE_KEY,
  FILTER_MEMORY_FEEL_KEY,
  FILTER_BOUNCE_KEY,
  FILTER_SOFTNESS_KEY,
  FILTER_CUSHIONING_DEPTH_KEY,
];

export const ONLY_ONE_SELECTED_FILTER_KEYS = [FILTER_WARRANTY_LENGTH_KEY, FILTER_INDENTATION_COVERAGE_KEY];

export enum SortOptionValues {
  DEFAULT = 'default',
  MOST_RELEVANT = 'most_relevant',
  RATING_DESC = 'rating_desc',
  PRICE_DESC = 'price_desc',
  PRICE_ASC = 'price_asc',
  MATCH_SCORE = 'match_score',
}

export type SortOption = {
  name: string;
  value: SortOptionValues;
};

export const MATTRESS_BRANDS_DEFAULT_SORT_OPTIONS = [
  {
    name: 'Most Relevant',
    value: SortOptionValues.DEFAULT,
  },
  {
    name: 'Highest Rated',
    value: SortOptionValues.RATING_DESC,
  },
  {
    name: 'Match Score (high to low)',
    value: SortOptionValues.MATCH_SCORE,
  },
  {
    name: 'Price (high to low)',
    value: SortOptionValues.PRICE_DESC,
  },
  {
    name: 'Price (low to high)',
    value: SortOptionValues.PRICE_ASC,
  },
];

export const MATTRESS_MODELS_DEFAULT_SORT_OPTIONS = [
  {
    name: 'Most Relevant',
    value: SortOptionValues.MOST_RELEVANT,
  },
  {
    name: 'Highest Rated',
    value: SortOptionValues.RATING_DESC,
  },
  {
    name: 'Match Score (high to low)',
    value: SortOptionValues.MATCH_SCORE,
  },
  {
    name: 'Price (high to low)',
    value: SortOptionValues.PRICE_DESC,
  },
  {
    name: 'Price (low to high)',
    value: SortOptionValues.PRICE_ASC,
  },
];

interface SleepPositionChoice extends ChoicesDefault {
  value: NumericalString<1 | 2 | 3>;
}

export const SLEEP_POSITION_OPTIONS: SleepPositionChoice[] = [
  {
    name: 'Side',
    value: 1,
  },
  {
    name: 'Back',
    value: 2,
  },
  {
    name: 'Stomach',
    value: 3,
  },
];

export const DEFAULT_SLEEPER_VALUE = {
  [FILTER_SLEEPER_ONE_KEY]: {
    [FILTER_SLEEP_POSITION_KEY]: [],
    [FILTER_WEIGHT_KEY]: [],
  },
  [FILTER_SLEEPER_TWO_KEY]: {
    [FILTER_SLEEP_POSITION_KEY]: [],
    [FILTER_WEIGHT_KEY]: [],
  },
};
