import { captureException } from '@sentry/browser';
import { Dispatch, SetStateAction } from 'react';
import isEmpty from 'lodash/isEmpty';
import { EMAIL, SMS } from '../../constants/commons';
import fetch from '../fetchService';
import { Deal, DealItem, SaveDeal, SaveDealItem } from '../../types/deal';
import { SaveDealsFormFormikProps } from '../../components/atoms/SaveDealsForm';

export type SaveDealsSentStateType = {
  show: boolean;
  email: boolean;
  sms: boolean;
  isMulti: boolean;
};

export const URL_DEAL_PARAM = 'show-deals-success';

export const DEFAULT_SAVE_DEALS_SENT_STATE = {
  show: false,
  email: false,
  sms: false,
  isMulti: false,
};

export const formatDataToSaveDeal = (deal: DealItem): SaveDealItem => {
  return {
    brand: deal.brand.id,
    retailer: deal.retailer.id,
    promotions: deal.promotions.map((p) => ({ promotion: p.id, promotion_type: p.promotion_type })),
  };
};

export const getBaseSaveDealData = (deal?: Deal): SaveDealItem[] => {
  const deals: SaveDealItem[] = [];

  if (!deal) return deals;

  return deal.deals.map((d) => formatDataToSaveDeal(d));
};

export const saveDeals = async (param: SaveDeal): Promise<unknown> => {
  const body: SaveDeal = {
    deals: param.deals,
  };

  const methods: string[] = [];
  if (!isEmpty(param.email)) {
    methods.push(EMAIL);
    body.email = param.email;
  }
  if (!isEmpty(param.phone)) {
    methods.push(SMS);
    body.phone = param.phone;
  }
  if (!isEmpty(param.name)) body.name = param.name;

  const { location } = window;
  const hasParams = !isEmpty(location.search);
  const isMultiDeals = body.deals.length > 1;
  const dealsHashParam = `${URL_DEAL_PARAM}=true&multi=${isMultiDeals}&methods=${methods.join('-')}`;
  body.next = hasParams ? `${location}&${dealsHashParam}` : `${location}?${dealsHashParam}`;

  return fetch.post('/promos/save-deals/', { ...body }).catch((err) => {
    const msg = 'There was an error saving your deals';
    console.error(msg, err);
    captureException(new Error(`${msg} -> ${err}`));

    return err;
  });
};

export const onSendDeals = async (
  values: SaveDealsFormFormikProps,
  setSaveDealsSent: Dispatch<SetStateAction<SaveDealsSentStateType>>,
  setShowVerifyEmailModal: Dispatch<SetStateAction<boolean>>,
  setCurrentEmail: Dispatch<SetStateAction<string>>,
  onClose: () => void,
): Promise<void> => {
  const { email, phone } = values;
  const isEmailEmpty = isEmpty(email);
  const isPhoneEmpty = isEmpty(phone);
  const sendDeals = !isEmailEmpty || !isPhoneEmpty;

  if (sendDeals) {
    await saveDeals(values).then((response: any) => {
      if (response?.verification_sent === true) {
        setCurrentEmail(email);
        setShowVerifyEmailModal(true);
      } else {
        setSaveDealsSent({ show: true, email: !isEmailEmpty, sms: !isPhoneEmpty, isMulti: values.deals.length > 1 });
      }

      onClose();
    });
  }
};
