export const EMPTY_EVENT_PRODUCT = {
    // applicable to ctas that correspond to a product
    collection: "", // the collection the product belongs to
    line: "", // the line the product belongs to
    listed: "",
    manufacturer: "", // the manufacturer of the product
    model: "", // the model name of the product
};

export const EMPTY_EVENT_STORE = {
    isLdp: "", // yes or no if the store is an ldp
    retailerLocation: "", // the specific location of the store
    retailerName: "", // the name of the retailer
};

export const CTA_CLICK_EVENT = {
    category: "", // the primary category the cta belongs
    subcategory: "", // additional cta category detail
    level: "", // the emphasis/styling of the cta
    url: "", // the href of the linked cta
    pageRegion: "", // the region on the page the cta resides in
    product: EMPTY_EVENT_PRODUCT,
    store: EMPTY_EVENT_STORE, // applicable to ctas that correspond to a store
};

export const STORE_LOCATOR_CLICK_EVENT = {
    isLdp: "", // yes or no if the specific result is an ldp
    position: 0, // the position of the result in the total result set
    retailerLocation: "", // the specific location of the store
    retailerName: "", // the name of the retailer
};

export const SITE_SEARCH_EVENT = {
    resultManufacturer: "", // applicable to terms taking the user to a product profile
    term: "", // the value used to perform the site search
    type: "", // how the search term was initialized
};

export const QUIZ_INTERACTION_EVENT = {
    answers: {
        age: "", // the age range selected in step 2 of the quiz
        budgetRange: 0, // the budget range selected in step 1 of the quiz
        deliveryZip: "", // the delivery zip code selected in step 1 of the quiz
        recipient: "", // the selection of who is shopped for in step 1 of the quiz
        size: "", // the bed size selected in step 1 of the quiz
        tryInStore: "", // whether or not the user is interested in trying in store
    },
    saveMethod: "", // how the user chooses to save their quiz results
    step: "", // the quiz step the event relates to
};

export const ONLINE_PROMO_INTERACTION_EVENT = {
    action: "", // the action that triggered the event
    engagementType: "", // the type of engagement, such as product or retailer
    pageRegion: "", // the region of the page the promo is located
    retailer: "", // the name of the retailer the promo belongs to
    type: "", // online or local
};

export const PRODUCT_QUICK_VIEW_EVENT = {
    pageRegion: "", // the region of the page the quick view is located
    product: EMPTY_EVENT_PRODUCT,
    store: EMPTY_EVENT_STORE, // applicable to quick views that correspond to a store
};

export const QUALIFYING_ACTION_EVENT = {
    action: "", // which action triggered the event
    authLevel: "", // the access level of the authenticated user
    memberId: "", // the member id assigned to the user
    userType: "", // the type of user identified
};

export const RETAILER_CASHBACK_CODE_INTERACTION_EVENT = {
    action: "",
    retailerLocation: "",
    retailerName: "",
    pageRegion: "",
    source: "",
    type: "",
};

export const PAGE_DATA_LOADED = {
    page: {
        // applicable to every page load
        type: "", // the type of page the user is viewing
        subtype: "", // additional page type detail
        comparisons: [
            /* specific to product comparison pages */
        ],
        editorial: {
            // specific to editorial content pages
            type: "", // the type of editorial content being viewed
        },
        product: [],
        store: {
            // specific to store pages
            isLdp: "", // yes or no if the store is an ldp
            retailerLocation: "", // the specific location of the store
            retailerName: "", // the name of the retailer
            retailerStatus: "", // whether the store is "open" or "closed"
        },
        storeLocator: {
            // specific to the store locator page
            ldpRetailerCount: 0, // the number of ldp retailers in the result set
            ldpStoreCount: 0, // the number of ldp store locations in the result set
            query: "", // the value used to perform the store search
            resultCount: 0, //
            resultInfo: [],
        },
    },
    user: {
        // applicable to every page load
        authLevel: "", // regular, staff, super admin
        authStatus: "", // whether or not the user is currently authenticated
        ldpProximate: 0, // the number of ldps within range of the user's estimated location
        ldpStoresNearby: [], // the list of ldps within range of the user's estimated location
    },
};
