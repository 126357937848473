import isEmpty from 'lodash/isEmpty';
import qs from 'qs';
import {
  LINE,
  MANUFACTURER,
  COLLECTION,
  MATTRESS_COLLECTION,
  MATTRESS,
  CLASSNAMES,
  HIERARCHY_CLASSNAMES_SHORT_MATTRESS,
  ROUTES,
} from '../constants/commons';
import { stringOccurrences } from './text';
import { MattressesBrandStoreDataProduct } from '../types/mattresses';
import { DISTANCE_FIELD } from '../constants/fields';

/**
 * Remove trailing slash from URL path.
 * @param url
 */
/**
 *  Get product's base URL according to its classname.
 *
 * @param className Product's classname.
 * @returns Product's base URL.
 */

export function getNoTrailingSlashPath(path: string): string {
  const slashesCount = stringOccurrences(path, '/');
  if (slashesCount <= 1) return path;

  const lastSlashIndex = path.lastIndexOf('/');

  return path.slice(0, lastSlashIndex) + path.slice(lastSlashIndex + 1);
}

export function getAnchorFormBaseUrl(url: string): string {
  const idx = url.indexOf('#');
  if (isEmpty(url) || idx === -1) return '';

  return url.substring(idx, url.length);
}

export function getQsStringifyParams(
  params: object,
  options: qs.IStringifyOptions = { indices: false, addQueryPrefix: true, skipNulls: true },
): string {
  return qs.stringify(params, options);
}

export const getProductBaseURL = (className: string): string => {
  if (![MANUFACTURER, LINE, COLLECTION, MATTRESS_COLLECTION, MATTRESS].includes(className)) {
    return '';
  }

  return className === MANUFACTURER ? '/mattresses/' : `/mattress-${CLASSNAMES[className as keyof typeof CLASSNAMES]}/`;
};

const MIN_REVIEWS_FOR_REVIEW_PAGE = 10;

export const getReviewsUrl = (classname: string, slug: string, ratingCount: number): string => {
  if (ratingCount < MIN_REVIEWS_FOR_REVIEW_PAGE) return `${getProductBaseURL(classname)}${slug}/#reviews`;

  return `${getProductBaseURL(classname)}${slug}/reviews/`;
};

export const getUrlAsDisplayText = (
  url: string | undefined,
  regex = /(http:\/\/|https:\/\/|www\.|dev\.|stage\.)/g,
): string => {
  return url?.replace(regex, '') || '';
};

export const getStoreLocatorURL = (
  brands: MattressesBrandStoreDataProduct[],
  distance?: string | null,
  zipcode?: string,
): string => {
  const params: Record<string, string[]> = {};

  brands.forEach(({ classname, slug }): void => {
    const prefix = HIERARCHY_CLASSNAMES_SHORT_MATTRESS[classname as keyof typeof HIERARCHY_CLASSNAMES_SHORT_MATTRESS];
    const value = prefix && slug ? `${prefix}_${slug}` : null;

    if (value) {
      if (params[prefix]) {
        params[prefix].push(value);
      } else {
        params[prefix] = [value];
      }
    }
  });

  const urlParams = getQsStringifyParams({ zip: zipcode, [DISTANCE_FIELD]: distance, ...params });
  return `${ROUTES.mattressStores}${urlParams}`;
};
