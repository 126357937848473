/* eslint-disable jsx-a11y/no-static-element-interactions */

'use client';

import React, { AriaAttributes, memo, ReactNode } from 'react';
import cls from 'classnames';
import Link from 'next/link';
import isNumber from 'lodash/isNumber';
import DataLayerService from 'datalayer-service';
import { CTAClick } from 'datalayer-service/src/types';
import { UrlObject } from 'url';
import { getStoreLocatorEventData } from '../../../utils/dataLayer';

export const DARK_GREEN = 'dark-green';
export const DEFAULT = 'default';

export const LINK_ROUTER_TEST_ID = 'link-router';
export const LINK_TEST_ID = 'link';

export type LinkButtonTypes = 'default' | 'promotion';
export interface LinkButtonProps {
  className?: string;
  to?: string | undefined;
  urlObject?: string | UrlObject;
  ariaLabel: string;
  ariaCurrent?: AriaAttributes['aria-current'];
  variant?:
    | 'dark-green'
    | 'light-green'
    | 'gray'
    | 'light-blue'
    | 'blue'
    | 'blue-filled'
    | 'quiz'
    | 'quiz-outline'
    | 'promo'
    | 'customer-rating';
  size?: 'sm' | 'lg';
  type?: LinkButtonTypes;
  target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
  rel?: string;
  children?: ReactNode;
  ctaData: CTAClick | undefined;
  position?: number;
  useRouter?: boolean;
  disabled?: boolean;
  scrollOnNavigation?: boolean;
  shallowNavigation?: boolean;
  preventPageChange?: boolean;
  onClick?(event: React.MouseEvent<HTMLElement>): void;
  onMouseEnter?(event: React.MouseEvent<HTMLElement>): void;
}

const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
  className,
  to,
  urlObject,
  ariaLabel,
  ariaCurrent,
  variant = DARK_GREEN,
  size = 'sm',
  type = DEFAULT,
  target = '_self',
  rel,
  children,
  ctaData,
  position,
  useRouter = true,
  disabled = false,
  onClick,
  onMouseEnter,
  scrollOnNavigation = true,
  shallowNavigation = false,
  preventPageChange = false,
}) => {
  const handleLinkClick = (e: React.MouseEvent<HTMLElement>): void => {
    if (shallowNavigation) {
      e.preventDefault();
      if (!preventPageChange) window.history.pushState({}, '', to || '');
    }

    if (ctaData) {
      const dataLayerService = new DataLayerService(window);

      dataLayerService.CTAClickEvent(ctaData);

      if (isNumber(position) && position >= 0 && ctaData.store) {
        dataLayerService.storeLocatorClickThroughEvent(getStoreLocatorEventData(ctaData.store!, position));
      }
    }

    if (onClick) onClick(e);
  };

  return useRouter && !shallowNavigation ? (
    <Link
      href={urlObject || to || ''}
      scroll={scrollOnNavigation}
      rel={rel}
      aria-current={ariaCurrent}
      data-testid={LINK_ROUTER_TEST_ID}
      aria-label={ariaLabel}
      target={target}
      onClick={handleLinkClick}
      className={cls(
        'btn',
        'link-btn',
        `link-btn-${variant}`,
        `link-btn-${size}`,
        `link-btn-${type}`,
        { disabled },
        className,
      )}
    >
      {children}
    </Link>
  ) : (
    <a
      aria-label={ariaLabel}
      aria-current={ariaCurrent}
      data-testid={LINK_TEST_ID}
      className={cls('btn', 'link-btn', `link-btn-${variant}`, `link-btn-${size}`, `link-btn-${type}`, className, {
        disabled,
      })}
      href={to}
      target={target}
      rel={rel}
      onClick={handleLinkClick}
      onMouseEnter={onMouseEnter}
    >
      {children}
    </a>
  );
};

export default memo(LinkButton);
