import React, { FC, useEffect, useState } from 'react';
import ReactPlaceholder from 'react-placeholder';
import cls from 'classnames';
import type { EmblaCarouselType } from 'embla-carousel';
import Modal from '../../atoms/Modal';
import useBreakPoints from '../../../hooks/useBreakPoints';
import VideoPlayer from '../VideoPlayer';

import styles from './MediaModal.module.scss';
import Carousel, { CarouselChildProps } from '../Carousel';
import { ProductStatus } from '../../../types/commons';
import DiscontinuedCaption from '../../atoms/DiscontinuedCaption';

export const MEDIA_MODAL_ID = 'media-modal';
const CAROUSEL_MEDIA_MODAL_ID = 'carousel-media-modal';

export interface MediaElement {
  alt: string;
  url?: string; // Video or Image URL
  isVideo?: boolean;
  youtubeId?: string;
  title?: string;
  description?: string;
}

interface MediaModalProps {
  show: boolean;
  isLoading: boolean;
  media: MediaElement[];
  onHide: () => void;
  unmount?: () => void; // this for Django React component
  title?: string;
  className?: string;
  showImageTitle?: boolean;
  rightContent?: (currentMediaIndex: number) => JSX.Element;
  showCaptions?: boolean;
  testId?: string;
  initialIndex?: number;
  productStatus?: ProductStatus.ACTIVE | ProductStatus.DISCONTINUED;
}

interface MediaCarouselChildProps extends CarouselChildProps {
  item: MediaElement;
  showCaptions: boolean;
  productStatus: ProductStatus.ACTIVE | ProductStatus.DISCONTINUED;
  onVideoStateChange: (videoIndex: number, state: 'pause' | 'play', emblaApi: EmblaCarouselType) => void;
}

const CarouselChild: FC<MediaCarouselChildProps> = ({
  index,
  startIndex,
  emblaApi,
  item,
  showCaptions,
  productStatus,
  onVideoStateChange,
}) => {
  const { alt, url, isVideo, youtubeId, title: mediaTitle, description } = item;

  if (isVideo && (youtubeId || url)) {
    return (
      <div className={cls(styles.mediaCarouselItemContainer, styles.mediaCarouselVideoItemContainer)}>
        <figure>
          <VideoPlayer
            youtubeEmbedUrl={url}
            youtubeId={youtubeId}
            key={`${youtubeId}-review-video`}
            showCaption={showCaptions}
            title={mediaTitle}
            description={description}
            playing={startIndex === index}
            onPlay={(): void => {
              if (index && emblaApi) onVideoStateChange(index, 'play', emblaApi);
            }}
            onPause={(): void => {
              if (index && emblaApi) onVideoStateChange(index, 'pause', emblaApi);
            }}
          />
          {showCaptions && <DiscontinuedCaption show={productStatus === ProductStatus.DISCONTINUED} />}
        </figure>
      </div>
    );
  }

  return (
    <div className={styles.mediaCarouselItemContainer}>
      <figure>
        <img src={url} alt={alt} loading="lazy" />
        {showCaptions && (mediaTitle || description) ? (
          <figcaption className={styles.mediaCarouselItemTitleCaption}>
            {mediaTitle || null}
            {description ? <p>{description}</p> : null}
          </figcaption>
        ) : null}
        {showCaptions && <DiscontinuedCaption show={productStatus === ProductStatus.DISCONTINUED} />}
      </figure>
    </div>
  );
};

const MediaModal: FC<MediaModalProps> = ({
  show,
  onHide,
  title,
  unmount,
  isLoading,
  media,
  className,
  rightContent,
  testId = MEDIA_MODAL_ID,
  showCaptions = false,
  initialIndex = 0,
  productStatus = ProductStatus.ACTIVE,
}) => {
  const { isMediumScreenSize } = useBreakPoints();
  const [playingVideos, setPlayingVideos] = useState<boolean[]>([]);
  const [currentMediaIndex, setCurrentMediaIndex] = useState<number>(initialIndex);
  const hasMoreThanOneMedia = media.length > 1;
  const ready = !isLoading;

  useEffect(() => {
    setPlayingVideos(new Array(media.length || 0).fill(false));
  }, [initialIndex, media]);

  const onVideoStateChange = (videoIndex: number, state: 'pause' | 'play', emblaApi: EmblaCarouselType): void => {
    const playingVideosCopy = [...playingVideos];
    playingVideosCopy[videoIndex] = state === 'play';

    setPlayingVideos(playingVideosCopy);

    if (emblaApi?.plugins) {
      if (state === 'play') emblaApi?.plugins()?.autoplay.stop();
      else emblaApi?.plugins()?.autoplay.play();
    }
  };

  const onSlideChange = (index: number): void => {
    setCurrentMediaIndex(index);
  };

  const getModalBody = (): JSX.Element => {
    return (
      <>
        <ReactPlaceholder
          ready={ready}
          type="rect"
          style={{ height: isMediumScreenSize ? '232px' : '400px', width: '100%', margin: '0' }}
          showLoadingAnimation
        >
          <div className={styles.videoContent}>
            <Carousel
              id={CAROUSEL_MEDIA_MODAL_ID}
              className={styles.mediaCarousel}
              startIndex={initialIndex}
              onSlideChange={onSlideChange}
              onCarouselMount={(emblaApi): void => {
                onVideoStateChange(currentMediaIndex, 'play', emblaApi);
              }}
            >
              {media.map((item, index) => (
                <CarouselChild
                  // eslint-disable-next-line react/no-array-index-key
                  key={`media-carousel-child-${index}`}
                  item={item}
                  showCaptions={showCaptions}
                  productStatus={productStatus}
                  onVideoStateChange={onVideoStateChange}
                />
              ))}
            </Carousel>
          </div>
        </ReactPlaceholder>

        {rightContent ? rightContent(currentMediaIndex) : null}
      </>
    );
  };

  return (
    <Modal
      id="mediaModal"
      show={show}
      onHide={(): void => {
        if (unmount) unmount();

        onHide();
      }}
      body={getModalBody()}
      title={
        title && isMediumScreenSize ? (
          <ReactPlaceholder
            ready={ready}
            type="textRow"
            showLoadingAnimation
            style={{
              height: isMediumScreenSize ? '30px' : '0',
              width: '180px',
              margin: 'auto',
            }}
          >
            {title}
          </ReactPlaceholder>
        ) : (
          ''
        )
      }
      bodyClassName={!hasMoreThanOneMedia ? styles.hasOneMedia : ''}
      className={cls(styles.mediaModal, className)}
      backdropIndex="1050"
      testId={testId}
    />
  );
};

export default MediaModal;
