import '../static/major/scss/home.scss';
import './maps';
import { slyHorizontal } from '../static/major/js/libs/sly/sly.horizontal';
import { renderSaveExtraMoney } from './react-components/HomeSaveExtraMoney';
import { renderPromoModal } from './react-components/PromoModal';
import { renderEditorialReviewModal } from './react-components/EditorialReviewModal';
import { renderFeaturedOnlineDiscounts } from './react-components/FeaturedOnlineDiscounts';

document.addEventListener('DOMContentLoaded', () => {
  const $q = jQuery('#q');

  $('#q-form').on('submit', () => {
    const val = $q.val().trim();
    $q.val(val);
    if (!val) {
      return false;
    }

    return true;
  });

  const setupSly = () => {
    const slyFrame = $('.sly-frame');

    if (slyFrame.length) {
      const screenSize = document.body.clientWidth || 0;
      const sly = slyHorizontal(slyFrame, {
        cycleBy: 'items',
        cycleInterval: 1000,
        easing: 'easeInSine',
        speed: screenSize < 576 ? 1000 : 4000,
        pauseOnHover: true,
      }).data('sly');

      sly.reload();
    }
  };

  const setupExpertReviewsSly = () => {
    const slyFrame = $('.expert_reviews-videos__sly-frame');

    if (slyFrame.length) {
      const sly = slyHorizontal(slyFrame, {
        cycleBy: 'items',
        easing: 'easeInSine',
        speed: 3000,
        pauseOnHover: true,
      }).data('sly');

      sly.reload();

      $('#expertReviewsControlPrev').on('click', () => {
        sly.prev();
      });

      $('#expertReviewsControlNext').on('click', () => {
        sly.next();
      });
    }
  };

  const handlePromotionClick = (deal) => {
    renderPromoModal(deal);
  };

  setupSly();
  setupExpertReviewsSly();
  renderSaveExtraMoney(handlePromotionClick);
  renderEditorialReviewModal();
  renderFeaturedOnlineDiscounts(handlePromotionClick);
});

// smooth scrolling for home <a /> click
window.smoothScroll = (target) => {
  let scrollContainer = target;
  do {
    // find scroll container
    scrollContainer = scrollContainer.parentNode;
    if (!scrollContainer) return;
    scrollContainer.scrollTop += 1;
  } while (scrollContainer.scrollTop === 0);

  let targetY = 0;
  do {
    // find the top of target relatively to the container
    if (target === scrollContainer) break;
    targetY += target.offsetTop;
    // eslint-disable-next-line
  } while ((target = target.offsetParent));

  // eslint-disable-next-line
  scroll = function (c, a, b, i) {
    // eslint-disable-next-line
    i++;
    if (i > 30) return;
    // eslint-disable-next-line
    c.scrollTop = a + ((b - a) / 30) * i;
    setTimeout(() => {
      // eslint-disable-next-line
      scroll(c, a, b, i);
    }, 20);
  };

  // start scrolling
  // eslint-disable-next-line
  scroll(scrollContainer, scrollContainer.scrollTop, targetY, 0);
};
