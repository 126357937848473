import { useEffect, useState } from 'react';
import fetch, { ApiVersion, DEFAULT_API_VERSION, FetchParams } from '../../utils/fetchService';
import {
  eliminateCacheDataFromSessionStorage,
  getCachedDataFromSessionStorage,
  setCacheDataToSessionStorage,
} from '../../utils/cache';
import useFirstRender from '../useFirstRender';

type UseFetch<T> = {
  isLoading: boolean;
  error: Error | null;
  data: T | null;
};

interface UseFetchOptions {
  apiVersion?: ApiVersion;
  shouldCache?: boolean;

  /**
   * Key that concatenates to the endpoint to form a more solid cache key.
   */
  cacheKey?: string;
}

const useFetch = <T = any>(
  endpoint: string,
  params: FetchParams = {},
  options?: UseFetchOptions,
  shouldFetch: boolean = true,
): UseFetch<T> => {
  const apiVersion = options?.apiVersion || DEFAULT_API_VERSION;

  /**
   * We currently do the caching in the SessionStorage.
   */
  const shouldCacheInSessionStorage = options?.shouldCache || false;
  const cacheKey = options?.cacheKey ? `${endpoint}_${options.cacheKey}` : endpoint;

  const isFirstRender = useFirstRender();

  const cacheData = shouldCacheInSessionStorage ? getCachedDataFromSessionStorage<T>(cacheKey) : null;

  const [data, setData] = useState<T | null>(cacheData || null);
  const [error, setError] = useState<Error | null>(null);
  const [isLoading, setIsLoading] = useState(!cacheData);

  const stringifiedParams = JSON.stringify(params);

  if (!shouldFetch) return { data, error, isLoading: false };

  useEffect(() => {
    /**
     * If it is the first render and there is cache data we omit the request to the server.
     */
    if (isFirstRender && cacheData) return;

    const fetchData = async (): Promise<void> => {
      setIsLoading(true);
      const parsedParams = JSON.parse(stringifiedParams);

      const anyParamFalsy = Object.values(parsedParams).some(
        (paramValue) => paramValue === undefined || paramValue === null,
      );

      /**
       * If any of the params is 'null' or 'undefined' we don't run the fetch call
       * due to the fact that we consider them as dependencies.
       */
      if (anyParamFalsy) return;

      try {
        eliminateCacheDataFromSessionStorage(cacheKey);
        const res = await fetch.get<T>(endpoint, parsedParams, {}, apiVersion);

        if (res) {
          setData(res);
          if (shouldCacheInSessionStorage) setCacheDataToSessionStorage(cacheKey, res);
        }
      } catch (err: unknown) {
        if (typeof err === 'string') {
          setError(new Error(err));
        } else if (err instanceof Error) {
          setError(err);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [endpoint, cacheKey, stringifiedParams]); // If cache key changes we rerun the request.

  return { data, error, isLoading };
};

export default useFetch;
