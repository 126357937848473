import React, { useState, lazy, Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/browser';
import { DEFAULT_SAVE_DEALS_SENT_STATE } from 'shared-ui/src/utils/saveDealsService';
import getUserData from '../user';
import { handleComponentUnmount } from './utils';

const PROMO_MODAL_ID = 'promo-modal';

const LazySUPromoModal = lazy(
  () => import(/* webpackChunkName: "promo-modal" */ 'shared-ui/src/components/molecules/PromoModal'),
);

function PromoModal({ show, deal, pageRegion, showSuccessModalData, unmount }) {
  const [showModal, setShowModal] = useState(show);

  return (
    <Suspense fallback={null}>
      <LazySUPromoModal
        show={showModal}
        user={window.userData}
        pageRegion={pageRegion}
        deal={deal}
        hasLoadedUser={!!window.userData}
        showSuccessModalData={showSuccessModalData}
        updateUser={getUserData}
        onHide={() => setShowModal(false)}
        unmount={unmount}
      />
    </Suspense>
  );
}

PromoModal.propTypes = {
  show: PropTypes.bool.isRequired,
  deal: PropTypes.shape(),
  pageRegion: PropTypes.string.isRequired,
  showSuccessModalData: PropTypes.shape(),
  unmount: PropTypes.func,
};

export default PromoModal;

export function renderPromoModal(deal = undefined, successData = DEFAULT_SAVE_DEALS_SENT_STATE, id = PROMO_MODAL_ID) {
  const element = document.getElementById(id);

  if (element) {
    const root = createRoot(element);
    root.render(
      <PromoModal
        show={!!deal}
        deal={deal}
        pageRegion={element.getAttribute('data-page-region')}
        showSuccessModalData={successData}
        unmount={() => {
          handleComponentUnmount(id, root);
        }}
      />,
    );
  } else {
    const errorMessage = `Element with id ${id} not found opening promo modal`;
    console.error(errorMessage);
    Sentry.captureException(new Error(errorMessage));
  }
}
