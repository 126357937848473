export const EMAIL = 'email';
export const SMS = 'sms';
export const MANUFACTURER = 'manufacturer';
export const LINE = 'line';
export const MATTRESS_COLLECTION = 'mattresscollection';
export const COLLECTION = 'collection';
export const MATTRESS = 'mattress';
export const MODEL = 'model';
export const STORE = 'store';
export const RETAILER = 'retailer';
export const CODE_ENDING = 'mw';

export const ICON_CLASS = 'custom-icon';

export const MATTRESS_HIERARCHY_CLASSNAMES = {
  [MANUFACTURER]: MANUFACTURER,
  [LINE]: LINE,
  [MATTRESS_COLLECTION]: COLLECTION,
  [MATTRESS]: MODEL,
} as const;

export const SHORT_MANUFACTURER = 'm';
export const SHORT_LINE = 'l';
export const SHORT_COLLECTION = 'c';
export const SHORT_MODEL = 'mod';
export const SHORT_STORE = 's';
export const SHORT_RETAILER = 'r';

export const SHORT_MATTRESS_HIERARCHY_CLASSNAMES = {
  [SHORT_MANUFACTURER]: MANUFACTURER,
  [SHORT_LINE]: LINE,
  [SHORT_COLLECTION]: MATTRESS_COLLECTION,
  [SHORT_MODEL]: MATTRESS,
};

export const SHORT_NAMES_BACKEND_PARAMS_MAPPER = {
  [SHORT_COLLECTION]: COLLECTION,
  [SHORT_LINE]: LINE,
  [SHORT_MODEL]: SHORT_MODEL,
  [SHORT_MANUFACTURER]: MANUFACTURER,
};

export const HIERARCHY_CLASSNAMES_SHORT_MATTRESS = {
  [MANUFACTURER]: SHORT_MANUFACTURER,
  [LINE]: SHORT_LINE,
  [MATTRESS_COLLECTION]: SHORT_COLLECTION,
  [MATTRESS]: SHORT_MODEL,
};

export const SHORT_CLASSNAMES = {
  ...SHORT_MATTRESS_HIERARCHY_CLASSNAMES,
  [SHORT_STORE]: STORE,
  [SHORT_RETAILER]: RETAILER,
};

export type MattressHierarchyClassNamesKeys = keyof typeof MATTRESS_HIERARCHY_CLASSNAMES;
export type MattressHierarchyClassNamesValues = (typeof MATTRESS_HIERARCHY_CLASSNAMES)[MattressHierarchyClassNamesKeys];

export const CLASSNAMES = {
  ...MATTRESS_HIERARCHY_CLASSNAMES,
  [STORE]: STORE,
  [RETAILER]: RETAILER,
};

export const ZIP_CODE_LENGTHS = [5, 6, 7];

export const ROUTES = {
  cashbackCode: '/cashback-code/',
  mattressFinder: '/mattress-finder/',
  mattressFinderQuiz: '/mattress-finder/quiz/',
  mattressFinderResults: '/mattress-finder/results/',
  individualReviewPage: '/mattress-review/',
  baseMattressReviewsForm: '/review-mattress/',
  mattressReviewsThanks: '/review-mattress/thanks/',
  mattressReviewsForm: '/review-mattress/',
  baseMattressStoreReviewsForm: '/review-mattress-store/',
  mattressStoreReviewsForm: '/review-mattress-store/',
  mattressStoreReviewsThanks: '/review-mattress-store/thanks/',
  signin: '/signin/',
  cashback: '/cashback/',
  cashbackThanks: '/cashback/thanks/',
  cashbackInvoice: '/cashback/:promotion?/:invoice?/',
  cashbackLanding: '/cashback-deals/',
  cashbackTestimonials: '/testimonials/cashback/',
  mattressModels: '/mattress-models/',
  mattressBrands: '/mattress-brands/',
  mattressReviewsList: '/mattress-reviews/',
  mattressDeals: '/mattress-deals/',
  mattresses: '/mattresses/',
  mattressCollection: '/mattress-collection/',
  mattressLine: '/mattress-line/',
  mattressModel: '/mattress-model/',
  mattressComparison: '/compare/',
  customMattressComparison: '/mattress-comparison/',
  mattressStores: '/mattress-stores/',
  mattressReviewList: '/reviews/',

  manageReviewRequests: {
    title: 'Manage Review Requests',
    href: '/manage/',
  },
  manageApiKeySettings: {
    title: 'Reviews API Settings',
    href: '/manage/publish/',
  },
  manageProductLookup: {
    title: 'Product Lookups',
    href: '/manage/products/',
  },
  manageDisplaySettings: {
    title: 'Display Settings',
    href: '/manage/display/',
  },
};

export const MIN_RATING_FOR_LDP_STORES = 4;

export const DELIVERY_POLICIES_KEY = 'delivery_policies';
export const RETURN_POLICIES_KEY = 'return_policies';
export const PRICE_PROTECTION_POLICIES_KEY = 'price_protection_policies';
export const FINANCING_POLICIES_KEY = 'financing_policies';

export const POLICIES = [
  DELIVERY_POLICIES_KEY,
  RETURN_POLICIES_KEY,
  PRICE_PROTECTION_POLICIES_KEY,
  FINANCING_POLICIES_KEY,
];

export const PROMOTION = 'promotion';
export const REBATE_PROMOTION = 'rebatepromotion';
export const BRAND_PROMO = 'brandpromotion';
export const RETAILER_PROMO = 'retailerpromotion';

export const PROMO_CLASSNAMES = {
  [PROMOTION]: PROMOTION,
  [REBATE_PROMOTION]: REBATE_PROMOTION,
  [RETAILER_PROMO]: RETAILER_PROMO,
  [BRAND_PROMO]: BRAND_PROMO,
};

export const SKIP_BUTTON_LABEL = "Don't know";

// LDP METRICS

export const LDP_METRICS_QUIZ_PAGE = 'QUIZ';
export const LDP_METRICS_STORE_LOCATOR_PAGE = 'STORE_LOCATOR';
export const LDP_METRICS_WEBSITE_ACTION = 'WEBSITE';
export const LDP_METRICS_PHONE_CALL_ACTION = 'PHONE_CALL';
export const LDP_METRICS_PHONE_HOVER_ACTION = 'PHONE_HOVER';
export const LDP_METRICS_SMS_OPEN_ACTION = 'SMS_OPEN';
export const LDP_METRICS_SMS_SEND_ACTION = 'SMS_SEND';
export const LDP_METRICS_DIRECTIONS_ACTION = 'DIRECTIONS';
export const LDP_METRICS_STORE_CLASSNAME = 'store';
export const LDP_METRCIS_RETAILER_CLASSNAME = 'retailer';
